import React from 'react';

const NoResultFoundSVG = () => {
    return (
        <svg
            width="160"
            height="129"
            viewBox="0 0 160 129"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            data-testid="no-result-found-svg"
        >
            <g clipPath="url(#clip0_476_4496)">
                <rect
                    width="160"
                    height="128.023"
                    fill="white"
                    fillOpacity="0.01"
                />
                <path
                    d="M73.7198 105.071L23.6913 123.386L0 58.6702L49.6373 40.498L73.7198 105.071Z"
                    fill="url(#paint0_linear_476_4496)"
                />
                <path
                    d="M65.604 97.2337L25.8625 111.774L26.3513 113.11L66.0927 98.5696L65.604 97.2337Z"
                    fill="#FFAB00"
                />
                <path
                    d="M52.3561 105.43L26.9417 114.729L27.4304 116.065L52.8449 106.766L52.3561 105.43Z"
                    fill="#FFAB00"
                />
                <path
                    d="M64.5234 94.2821L24.782 108.823L25.2707 110.159L65.0122 95.6179L64.5234 94.2821Z"
                    fill="#C1C7D0"
                />
                <path
                    d="M18.9907 63.5908L9.49951 67.0635L9.98827 68.3993L19.4795 64.9267L18.9907 63.5908Z"
                    fill="#0065FF"
                />
                <path
                    d="M18.2015 60.5365L8.4231 64.1152L8.912 65.4511L18.6904 61.8723L18.2015 60.5365Z"
                    fill="#0065FF"
                />
                <path
                    d="M52.3026 60.8851L12.2739 75.5352L23.0078 104.864L63.0365 90.2136L52.3026 60.8851Z"
                    fill="#F4F5F7"
                />
                <path
                    opacity="0.3"
                    d="M49.6374 40.498L40.1423 43.976V89.865H68.0513L49.6374 40.498Z"
                    fill="url(#paint1_linear_476_4496)"
                />
                <path
                    d="M113.364 15.2489V82.7525H49.3884V0H98.1082L113.357 15.2489H113.364Z"
                    fill="url(#paint2_linear_476_4496)"
                />
                <path
                    opacity="0.5"
                    d="M90.946 82.7527H113.364V64.7513L103.229 59.417L90.946 82.7527Z"
                    fill="#97A0AF"
                />
                <path
                    d="M77.027 72.0746L77.0168 73.4971L105.772 73.7033L105.782 72.2809L77.027 72.0746Z"
                    fill="#0065FF"
                />
                <path
                    d="M65.7682 72.2832H56.9773V73.7057H65.7682V72.2832Z"
                    fill="#0065FF"
                />
                <path
                    d="M71.4511 77.0697C73.8197 77.0697 75.7399 75.1496 75.7399 72.7809C75.7399 70.4123 73.8197 68.4922 71.4511 68.4922C69.0825 68.4922 67.1624 70.4123 67.1624 72.7809C67.1624 75.1496 69.0825 77.0697 71.4511 77.0697Z"
                    fill="#253858"
                />
                <path
                    d="M76.5506 30.839L89.5734 37.8376C90.5051 38.3355 90.5051 39.6797 89.5734 40.1776L76.5506 47.1762C75.6687 47.6527 74.5947 47.0126 74.5947 46.0097V32.0126C74.5947 31.0097 75.6687 30.3696 76.5506 30.839Z"
                    fill="#FFAB00"
                />
                <path
                    d="M80.8963 56.8707C71.0528 56.8707 63.0371 48.8622 63.0371 39.0115C63.0371 29.1609 71.0456 21.1523 80.8963 21.1523C90.7398 21.1523 98.7555 29.1609 98.7555 39.0115C98.7555 48.8622 90.7398 56.8707 80.8963 56.8707ZM80.8963 22.5819C71.8351 22.5819 64.4596 29.9575 64.4596 39.0186C64.4596 48.0798 71.8351 55.4553 80.8963 55.4553C89.9574 55.4553 97.333 48.0798 97.333 39.0186C97.333 29.9575 89.9574 22.5819 80.8963 22.5819Z"
                    fill="#FFAB00"
                />
                <path
                    d="M98.1223 23.4637L113.364 15.2489L98.1223 0V23.4637Z"
                    fill="url(#paint3_linear_476_4496)"
                />
                <path
                    d="M113.364 15.2489H98.1223V0L113.364 15.2489Z"
                    fill="#FAFBFC"
                />
                <path
                    d="M135.363 128.023L88.5491 103.385L113.186 56.5718L160 81.209L135.363 128.023Z"
                    fill="url(#paint4_linear_476_4496)"
                />
                <path
                    d="M133.775 122.87L154.864 82.7979L114.792 61.7089L93.7028 101.781L133.775 122.87Z"
                    fill="#FAFBFC"
                />
                <path
                    d="M103.969 94.2531L117.945 89.9146L122.276 103.883L103.969 94.2531Z"
                    fill="#253858"
                />
                <path
                    d="M110.398 97.6313L128.528 92.0054L134.161 110.135L110.398 97.6313Z"
                    fill="#0065FF"
                />
                <path
                    d="M122.482 83.0155C124.226 83.0155 125.64 81.6017 125.64 79.8576C125.64 78.1135 124.226 76.6997 122.482 76.6997C120.738 76.6997 119.324 78.1135 119.324 79.8576C119.324 81.6017 120.738 83.0155 122.482 83.0155Z"
                    fill="#FFAB00"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_476_4496"
                    x1="10.3227"
                    y1="111.338"
                    x2="63.1547"
                    y2="52.6659"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.1018" stopColor="#DFE1E5" />
                    <stop offset="1" stopColor="#F0F2F5" stopOpacity="0.6" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_476_4496"
                    x1="54.0958"
                    y1="89.8675"
                    x2="54.0958"
                    y2="7.11256"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6C798F" stopOpacity="0" />
                    <stop offset="1" stopColor="#6C798F" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_476_4496"
                    x1="44.6916"
                    y1="78.0615"
                    x2="110.436"
                    y2="12.3169"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.2636" stopColor="#C1C7D0" />
                    <stop offset="0.8949" stopColor="#F0F2F5" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_476_4496"
                    x1="94.0114"
                    y1="19.3546"
                    x2="110.068"
                    y2="3.29809"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FAFBFC" />
                    <stop offset="0.2786" stopColor="#EFF1F3" />
                    <stop offset="0.7688" stopColor="#D1D6DD" />
                    <stop offset="1" stopColor="#C1C7D0" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear_476_4496"
                    x1="100.869"
                    y1="115.702"
                    x2="147.681"
                    y2="68.8903"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.3876" stopColor="#C1C7D0" />
                    <stop
                        offset="0.7081"
                        stopColor="#C3C9D1"
                        stopOpacity="0.7383"
                    />
                    <stop
                        offset="0.8236"
                        stopColor="#CACFD7"
                        stopOpacity="0.6441"
                    />
                    <stop
                        offset="0.9058"
                        stopColor="#D5DAE0"
                        stopOpacity="0.5769"
                    />
                    <stop
                        offset="0.9719"
                        stopColor="#E6E9ED"
                        stopOpacity="0.5229"
                    />
                    <stop offset="1" stopColor="#F0F2F5" stopOpacity="0.5" />
                </linearGradient>
                <clipPath id="clip0_476_4496">
                    <rect width="160" height="128.023" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default NoResultFoundSVG;
