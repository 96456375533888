import { string, shape, arrayOf, oneOf } from 'prop-types';
import { ProductType } from '../product/product.type';
import { ResourceType } from '../card/card.type';
// @ts-ignore
import { GuideType } from '../../pages/collection/collection.type';

const AdditionalLink = shape({
    linkUrl: string,
    linkLabel: string,
    id: string
});

export const HeaderType = shape({
    atlassianSupportLogo: shape({
        url: string
    }),
    serverProducts: arrayOf(ProductType),
    cloudProducts: arrayOf(ProductType),
    additionalLinks: arrayOf(AdditionalLink),
    id: string.isRequired,
    resources: shape({
        id: string.isRequired,
        cards: arrayOf(ResourceType),
        type: oneOf(['defaultResourceCards'])
    }),
    guides: shape({
        id: string,
        cards: arrayOf(GuideType),
        type: oneOf(['collection'])
    })
});

export * from '../../pages/product-landing/product-landing-type';
