import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../../providers/auth-providers';
import { getExperiment } from '../../../statsig/statsig-client';
import analytics from '../../utils/analytics';
import Logger from '../../../logger';
import { sentryCaptureException } from '../../utils/sentry/sentry-client';

export interface ExperimentWrapperProps {
    experimentName: string;
    experimentParameter: string;
    defaultValues: Record<string, any>;
    customAttributes?: Record<string, any>;
    children: React.ReactNode;
}

export const ExperimentWrapper = ({
    experimentName,
    experimentParameter,
    defaultValues,
    customAttributes,
    children
}: ExperimentWrapperProps) => {
    const [shouldRenderFeature, setShouldRenderFeature] = useState(false);
    const { aaId } = useAuth();

    const sendExperimentEvent = useCallback(
        (experimentResult: any, aaId: string) => {
            const eventMetadata = {
                action: 'evaluated',
                actionSubject: experimentName,
                actionSubjectId: `${experimentName} evaluated`
            };

            const attributes = {
                ...experimentResult,
                ...customAttributes,
                aaId,
                experimentName
            };

            analytics.sendGASV3OperationalEvent(eventMetadata, attributes);
        },
        [customAttributes, experimentName]
    );

    const isFeatureEnabled = useCallback(
        async (aaId: string) => {
            const identifiers = {
                atlassianAccountId: aaId
            };

            const experimentResult = await getExperiment(
                experimentName,
                identifiers
            );

            sendExperimentEvent(experimentResult, aaId);

            return experimentResult.getValue(
                experimentParameter,
                defaultValues
            );
        },
        [
            defaultValues,
            experimentName,
            experimentParameter,
            sendExperimentEvent
        ]
    );

    useEffect(() => {
        if (aaId) {
            isFeatureEnabled(aaId)
                // @ts-ignore
                .then((isEnabled: boolean) => {
                    setShouldRenderFeature(!!isEnabled);
                })
                .catch((error) => {
                    Logger.warn(
                        {
                            error
                        },
                        `Error evaluating experiment ${experimentName}`
                    );
                    sentryCaptureException({
                        exception: error
                    });
                    setShouldRenderFeature(false);
                });
        } else {
            setShouldRenderFeature(false);
        }
    }, [aaId, isFeatureEnabled, experimentName]);

    return shouldRenderFeature && <>{children}</>;
};
