import React from 'react';
import Tabs, { Tab, TabList, TabPanel } from '@atlaskit/tabs';
import { ProductCardsGroup } from '../product-card';
import { ProductContext } from '../../../content';
import './deployment-selector-tabs.less';
import analytics from '../../utils/analytics/analytics';
import camelCase from 'lodash/camelCase';
import { AnalyticsMetadata } from '../../utils/analytics/types';

const handleTabSelect = (details: string, tab: string) => {
    const tabName = tab === 'Data Center' ? 'Server' : tab;
    analytics.trackEvent(camelCase(tabName + ' tab'), {
        //@ts-ignore
        event: 'clicked',
        eventComponent: 'tab',
        category: 'products cloud and server tabs',
        actionSubjectId: camelCase(details + ' products ' + tabName + ' tab'),
        action: 'click',
        label: tab
    });
};

interface GetTabContentProps {
    tabData: {
        cloudProducts: ProductContext[];
        serverProducts: ProductContext[];
    };
    analyticsMetadata: AnalyticsMetadata;
}

const getTabContent = ({ tabData, analyticsMetadata }: GetTabContentProps) => [
    {
        label: 'Cloud',
        content: (
            <ProductCardsGroup
                productList={tabData.cloudProducts}
                analyticsMetadata={analyticsMetadata}
            />
        )
    },
    {
        label: 'Data Center',
        content: (
            <ProductCardsGroup
                productList={tabData.serverProducts}
                analyticsMetadata={analyticsMetadata}
            />
        )
    }
];

export const DeploymentSelectorTabs = ({
    serverProducts,
    cloudProducts,
    details,
    analyticsMetadata
}: {
    serverProducts: ProductContext[];
    cloudProducts: ProductContext[];
    details: string;
    analyticsMetadata: AnalyticsMetadata;
}) => {
    const TAB_CONTENT = getTabContent({
        tabData: { cloudProducts, serverProducts },
        analyticsMetadata
    });

    return (
        <Tabs
            id="deployment-selector-tab"
            testId="deployment-selector-tab"
            onChange={(selected) => {
                TAB_CONTENT[selected].label &&
                    handleTabSelect(details, TAB_CONTENT[selected].label);
            }}
        >
            <TabList>
                {TAB_CONTENT.map((tab) => (
                    <Tab
                        key={tab.label}
                        testId={`${tab.label.replace(' ', '-')}-tab`}
                    >
                        {tab.label}
                    </Tab>
                ))}
            </TabList>
            {TAB_CONTENT.map((tab) => (
                <TabPanel key={tab.label}>{tab.content}</TabPanel>
            ))}
        </Tabs>
    );
};
