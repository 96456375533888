import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
    Deployment,
    SearchOptionsGenericValue,
    SortBy,
    SearchOptionsPlatformValue,
    SearchOptionsCategoryValue
} from '../types';
import { getVersionFilters } from '../../../utils/get-filters';
import { SearchResultsPageEntry } from '../search-results';
import { ListFilterOption } from '../../../components/list-filter';
import {
    ContentTypeTab,
    DEFAULT_CONTENT_TYPE_TAB
} from '../components/content-type-tabs';

export interface CategoryTab {
    key: string;
    name: string;
    label: string;
    testId: string;
}

export const DEFAULT_CATEGORY_TAB: CategoryTab = {
    key: 'all',
    name: 'All',
    label: 'All',
    testId: 'all-tab'
};

export interface FilterState {
    entry: SearchResultsPageEntry;
    contentTypeFilterValue: ContentTypeTab;
    deploymentQueryValue?: Deployment;
    productFilterValue: ListFilterOption;
    productsFilterValues: SearchOptionsPlatformValue[];
    versionsQueryValue: string[];
    versionsFilterValues: ListFilterOption[];
    sortByQueryValue: SortBy;
    sortByFilterValues: SearchOptionsGenericValue[];
    contentTypeValues: SearchOptionsGenericValue[];
    currentResultIndex: number;
    categoryValues: SearchOptionsCategoryValue[];
    categoryFilterValue: CategoryTab;
    categoryContentTypeFilterValues: SearchOptionsGenericValue[];
}

const initialState: FilterState = {
    entry: {
        id: '',
        type: '',
        url: '',
        resources: {
            id: '',
            cards: [],
            type: ''
        },
        header: {
            resources: undefined,
            additionalLinks: [],
            logo: '',
            id: '',
            showPreviewBanner: false,
            pageId: '',
            atlassianLogo: {
                url: ''
            },
            atlassianSupportLogo: {
                url: ''
            },
            serverProducts: [],
            cloudProducts: []
        },
        preview: false,
        searchOptions: {
            filters: {
                platforms: [],
                contentTypes: [],
                categories: []
            },
            sortOptions: []
        }
    },
    contentTypeFilterValue: DEFAULT_CONTENT_TYPE_TAB,
    deploymentQueryValue: undefined,
    productFilterValue: {
        key: '',
        label: '',
        name: ''
    },
    productsFilterValues: [],
    versionsQueryValue: [],
    versionsFilterValues: [],
    sortByQueryValue: SortBy.RELEVANCE,
    sortByFilterValues: [{ key: SortBy.RELEVANCE, name: 'Relevance' }],
    contentTypeValues: [],
    currentResultIndex: 0,
    categoryValues: [],
    categoryFilterValue: DEFAULT_CATEGORY_TAB,
    categoryContentTypeFilterValues: []
};

export const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        resetAllValues: (state) => {
            state.deploymentQueryValue = undefined;
            state.productFilterValue = {
                key: '',
                label: '',
                name: ''
            };
            state.versionsQueryValue = [];
            state.currentResultIndex = 0;
            state.categoryContentTypeFilterValues = [];
        },
        resetProductFilterValue: (state) => {
            state.deploymentQueryValue = undefined;
            state.productFilterValue = {
                key: '',
                label: '',
                name: ''
            };
            state.versionsQueryValue = [];
            state.currentResultIndex = 0;
        },
        setContentTypeFilterValue: (
            state,
            action: PayloadAction<ContentTypeTab>
        ) => {
            state.contentTypeFilterValue = action.payload;
            state.currentResultIndex = 0;
        },
        setProductFilterValue: (
            state,
            action: PayloadAction<{
                deployment: Deployment;
                product: ListFilterOption;
            }>
        ) => {
            state.deploymentQueryValue = action.payload.deployment;
            state.productFilterValue = action.payload.product;
            if (state.deploymentQueryValue === Deployment.DATA_CENTER) {
                state.versionsFilterValues = getVersionFilters(
                    state.productFilterValue.versions
                );
            }
            state.versionsQueryValue = [];
            state.currentResultIndex = 0;
        },
        setVersionsFilterQueryValue: (
            state,
            action: PayloadAction<string[]>
        ) => {
            state.versionsQueryValue = action.payload;
            state.currentResultIndex = 0;
        },
        setSortByQueryValue: (state, action: PayloadAction<SortBy>) => {
            state.sortByQueryValue = action.payload;
            state.currentResultIndex = 0;
        },
        setCurrentResultIndex: (state, action: PayloadAction<number>) => {
            state.currentResultIndex = action.payload;
        },
        setCategoryFilterValue: (state, action: PayloadAction<CategoryTab>) => {
            state.categoryFilterValue = action.payload;
            state.categoryContentTypeFilterValues = [];
            state.currentResultIndex = 0;
        },
        setCategoryContentTypeFilterValues: (
            state,
            action: PayloadAction<SearchOptionsGenericValue[]>
        ) => {
            state.categoryContentTypeFilterValues = action.payload;
            state.currentResultIndex = 0;
        },
        saveEntry: (state, action: PayloadAction<any>) => {
            state.entry = action.payload;

            if (state.entry?.searchOptions?.filters?.platforms) {
                state.productsFilterValues =
                    state.entry?.searchOptions?.filters?.platforms;
            }
            if (state.entry?.searchOptions?.sortOptions) {
                state.sortByFilterValues =
                    state.entry?.searchOptions?.sortOptions;
            }
            if (state.entry?.searchOptions?.filters?.contentTypes) {
                state.contentTypeValues =
                    state.entry?.searchOptions?.filters?.contentTypes;
            }
            if (state.entry?.searchOptions?.filters?.categories) {
                state.categoryValues =
                    state.entry?.searchOptions?.filters?.categories;
            }
        }
    }
});

export const {
    setContentTypeFilterValue,
    setProductFilterValue,
    setVersionsFilterQueryValue,
    resetAllValues,
    saveEntry,
    setSortByQueryValue,
    setCurrentResultIndex,
    setCategoryFilterValue,
    setCategoryContentTypeFilterValues,
    resetProductFilterValue
} = filterSlice.actions;

export default filterSlice.reducer;
