import { SearchOptionsCategoryValue, SearchOptionsGenericValue } from './types';

export const getContentTypeFilterQuery = ({
    isNewFilterUXEnabled,
    categoryContentTypeFilterValues,
    categoryFilterValue,
    categoryValues
}: {
    isNewFilterUXEnabled: boolean;
    categoryContentTypeFilterValues: SearchOptionsGenericValue[];
    categoryFilterValue: SearchOptionsGenericValue;
    categoryValues: SearchOptionsCategoryValue[];
}): string[] => {
    if (!isNewFilterUXEnabled) {
        return [];
    }

    if (categoryContentTypeFilterValues?.length > 0) {
        return categoryContentTypeFilterValues.map(
            (contentType) => contentType.key
        );
    }

    if (categoryFilterValue.key !== 'all') {
        const selectedCategory = categoryValues.find(
            (category) => category.key === categoryFilterValue.key
        );
        if (selectedCategory?.contentTypes) {
            return selectedCategory.contentTypes.map(
                (contentType) => contentType.key
            );
        }
    }

    return [];
};

export const getUpdatedSearchUrl = ({
    searchValue,
    deployment,
    product,
    version,
    contentTypeFilter
}: {
    searchValue: string;
    deployment: string | undefined;
    product: string | undefined;
    version: string;
    contentTypeFilter: string[];
}) => {
    const url = new URL(window.location.href);
    url.searchParams.set('searchTerm', searchValue);

    deployment
        ? url.searchParams.set('deployment', deployment)
        : url.searchParams.delete('deployment');
    product
        ? url.searchParams.set('product', product)
        : url.searchParams.delete('product');
    version
        ? url.searchParams.set('version', version)
        : url.searchParams.delete('version');

    contentTypeFilter.length > 0
        ? url.searchParams.set('contentType', contentTypeFilter.join(','))
        : url.searchParams.delete('contentType');

    return url;
};
