export const STATSIG_EXPERIMENTS = {
    CSM_AI_AGENT_ON_SAC: 'csm-ai-agent-on-sac',
    SAC_SEARCH_AI: 'sac-search-ai-experiment',
    SAC_NEW_CONTENT_UX: 'sac-new-content-ux-experiment-gate'
};

interface ExperimentDefaults {
    'csm-ai-agent-on-sac': { agent_enabled: boolean };
    'sac-search-ai-experiment': { ai_dialog_enabled: boolean };
    'sac-new-content-ux-experiment-gate': { new_ux_enabled: boolean };
}

/** These are the parameters passed into experiments in Statsig. */
export const STATSIG_EXPERIMENTS_DEFAULTS: ExperimentDefaults = {
    'csm-ai-agent-on-sac': {
        agent_enabled: false
    },
    'sac-search-ai-experiment': {
        ai_dialog_enabled: false
    },
    'sac-new-content-ux-experiment-gate': {
        new_ux_enabled: false
    }
};
