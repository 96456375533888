import {
    GqlKbArticlePage,
    KbArticlePage
} from '../../../types/content/kb-article';
import { getBodyAndTableOfContents } from './get-body-and-table-of-contents';

export type KbArticleCommonTransformations = Pick<
    KbArticlePage,
    'body' | 'tableOfContents' | 'platform' | 'appVersions' | 'productVersions'
>;

// Transform common data between preview and non-preview
export const kbArticleCommonTransformer = (
    kbArticlePage: GqlKbArticlePage
): KbArticleCommonTransformations => {
    const { articleBody } = kbArticlePage;
    const { body, tableOfContents } = getBodyAndTableOfContents(articleBody);
    const platform = articleBody.platform;
    const appVersions = articleBody.appsCollection?.items ?? [];
    const productVersions = articleBody.productsCollection?.items ?? [];

    return {
        body,
        tableOfContents,
        platform,
        appVersions,
        productVersions
    };
};
