import React from 'react';
import { RendererProps } from '@atlaskit/renderer';
import { LinkExtension } from './link-extension';
import { IconExtension } from './icon-extension';
import get from 'lodash/get';
import { MediaExtension } from './media-extension';
import { TextFragmentExtension } from './text-fragment-extension';

const getComAtlassianMesaHandler = (extensionsData: any) => {
    // eslint-disable-next-line react/display-name, complexity
    return (ext: any) => {
        const data = extensionsData[ext.extensionKey];
        if (ext.extensionKey === 'sac-link') {
            const { id, label: storedLabel } = ext.parameters;
            let href = '';
            let label = '';

            if (data.SACLinksDictionary[id]) {
                href = data.SACLinksDictionary[id].href;
                label = storedLabel || data.SACLinksDictionary[id].label;
            }

            return (
                <LinkExtension
                    href={href}
                    label={label}
                    shouldPreventRedirect={data.shouldPreventRedirect}
                />
            );
        } else if (ext.extensionKey === 'icon') {
            const { iconKey } = ext.parameters;

            return <IconExtension iconKey={iconKey} allIcons={data.allIcons} />;
        } else if (ext.extensionKey === 'media') {
            const { mediaDictionary } = data;
            const mediaProps = ext.parameters;

            const mediaEntryId = get(mediaProps, 'mediaEntryId');
            const imageData = mediaDictionary[mediaEntryId];
            // language namespace isn't included if request comes from deliveryClient
            const imgSrc = get(imageData, 'imageAsset.fields.file.en-US.url')
                ? get(imageData, 'imageAsset.fields.file.en-US.url', '')
                : get(imageData, 'imageAsset.fields.file.url');
            const altTag = get(imageData, 'mediaEntry.fields.altTag.en-US')
                ? get(imageData, 'mediaEntry.fields.altTag.en-US', '')
                : get(imageData, 'mediaEntry.fields.altTag', '');

            return <MediaExtension imgSrc={imgSrc} altTag={altTag} />;
        } else if (ext.extensionKey === 'textFragment') {
            const { textFragmentDictionary } = data;

            const isInline = ext.type === 'inlineExtension';
            const entryData = ext.parameters;
            const entryId = get(entryData, 'textFragmentId');
            const textFragmentData = get(textFragmentDictionary, entryId);

            const adf = get(
                textFragmentData,
                'textFragmentEntry.fields.reusableText.en-US'
            )
                ? get(
                      textFragmentData,
                      'textFragmentEntry.fields.reusableText.en-US',
                      ''
                  )
                : get(
                      textFragmentData,
                      'textFragmentEntry.fields.reusableText'
                  );

            return (
                <TextFragmentExtension
                    extensionHandlers={getExtensionHandlers(extensionsData)}
                    adf={adf}
                    isInline={isInline}
                />
            );
        }
        return null;
    };
};

export const getExtensionHandlers = (
    extensionsData: any
): RendererProps['extensionHandlers'] => {
    return {
        'com.atlassian.mesa': getComAtlassianMesaHandler(extensionsData)
    };
};
