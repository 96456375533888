import React, { useCallback, useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import Pagination from '@atlaskit/pagination';
import Spinner from '@atlaskit/spinner';
import { HeroBanner } from '../../components/hero-banner';
import { Search } from '../../components/search';
import Footer from '../../components/footer';
import { Header, HeaderProps, Resources } from '../../components/header';
import './search-results.less';
import { translatableText } from '../../utils/translatable-text';
import {
    SearchResultsDisplay,
    SearchResultCard,
    NoSearchResults
} from '../../components/search-results';
import FilterSelector from './components/filter-selector';
import ResetButton from './components/reset-button';
import {
    ContentTypeTab,
    ContentTypeTabs,
    DEFAULT_CONTENT_TYPE_TAB
} from './components/content-type-tabs';
import {
    Deployment,
    SearchOptionsApiResponse,
    SearchOptionsGenericValue,
    SortBy
} from './types';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from './state/store';
import { updateSearchTerm } from './state/search/search-reducer';
import {
    MAX_NUM_OF_RESULTS,
    NUM_OF_RESULTS_PER_PAGE,
    SearchQuery,
    fetchSearchResults
} from './state/search/utils/fetch-search-results';
import Logger from '../../../logger';
import {
    setVersionsFilterQueryValue,
    saveEntry,
    setSortByQueryValue,
    resetAllValues,
    setProductFilterValue,
    setContentTypeFilterValue,
    setCurrentResultIndex,
    setCategoryContentTypeFilterValues,
    setCategoryFilterValue,
    resetProductFilterValue
} from './state/filter-reducer';
import {
    ListFilter,
    MultiSelectListFilter
} from '../../components/list-filter';
import { Divider } from '../../components/divider';
import analytics from '../../utils/analytics';
import { ResultsText } from '../../components/search-results/results-text';
import { FilterTags } from '../../components/filter-tags';
import { getExperiment } from '../../../statsig/statsig-client';
import { SearchAIContainer } from '../../components/search-ai/search-ai-container';
import { getContentTypeFilterQuery, getUpdatedSearchUrl } from './utils';
import { STATSIG_EXPERIMENTS_DEFAULTS } from '../../../statsig/experiments';

export interface SearchResultsPageEntry {
    id: string;
    type: string;
    url: string;
    resources: Resources;
    header: HeaderProps;
    preview: boolean;
    searchOptions: SearchOptionsApiResponse;
}

interface SearchResultsPageProps {
    entry: SearchResultsPageEntry;
    featureFlags: {
        isAiEnabled?: boolean;
        isNavRedesignEnabled?: boolean;
    };
}

// eslint-disable-next-line complexity
export const SearchResultsPage = ({
    entry,
    featureFlags
}: SearchResultsPageProps) => {
    const { isAiEnabled, isNavRedesignEnabled } = featureFlags;

    const { isLoading, searchTerm, searchResponse, showPagination } =
        useSelector((state: RootState) => state.search);

    const {
        contentTypeFilterValue,
        deploymentQueryValue,
        productFilterValue,
        productsFilterValues,
        versionsQueryValue,
        versionsFilterValues,
        sortByQueryValue,
        sortByFilterValues,
        contentTypeValues,
        currentResultIndex,
        categoryFilterValue,
        categoryValues,
        categoryContentTypeFilterValues
    } = useSelector((state: RootState) => state.filter);

    const dispatch: AppDispatch = useDispatch();

    const isFeatureFlagEvaluated = useRef(false);
    const [isNewFilterUXEnabled, setIsNewFilterUXEnabled] = useState(false);
    const [topTabs, setTopTabs] = useState<React.ReactNode>(null);
    const [productFilter, setProductFilter] = useState<React.ReactNode>(null);
    const [versionFilter, setVersionFilter] = useState<React.ReactNode>(null);

    // used to cancel the search request if a new search request is made
    // while the previous one is still in progress
    const abortController = useRef<AbortController | null>(null);

    const searchHandler = async (
        dispatch: AppDispatch,
        searchTerm: string,
        query: SearchQuery,
        searchStartTime: number,
        isNewFilterUXEnabled: boolean
        // eslint-disable-next-line max-params
    ) => {
        if (abortController.current) {
            abortController.current.abort();
        }

        try {
            abortController.current = new AbortController();

            await dispatch(
                fetchSearchResults({
                    searchTerm,
                    query,
                    facets: !isNewFilterUXEnabled,
                    signal: abortController.current.signal
                })
            ).unwrap();

            const searchResponseTime = Date.now() - searchStartTime;
            analytics.sendGASV3OperationalEvent(
                {
                    action: 'rendered',
                    actionSubject: 'results',
                    actionSubjectId: 'searchResults'
                },
                { searchTerm, responseTime: searchResponseTime }
            );
        } catch (error) {
            // For AbortError, we don't need to do anything since the isLoading state
            // will be kept true in the reducer until the new request completes
            if ((error as Error).name !== 'AbortError') {
                Logger.error({ error }, 'Error dispatching the search handler');
            }
        }
    };

    const debouncedQueryHelpAggregator = debounce(searchHandler, 500);

    const handleSearchChange = (searchValue: string) => {
        // @ts-ignore
        const action = updateSearchTerm(searchValue);
        dispatch(action);
    };

    const handleSubmit = (searchValue: string) => {
        if (!searchValue) {
            return;
        }

        const searchStartTime = Date.now();
        const contentTypeTab = contentTypeFilterValue?.key;
        const deployment = deploymentQueryValue;
        const product = productFilterValue.key;
        const version = versionsQueryValue[0] || '';

        const contentTypeFilterQuery = getContentTypeFilterQuery({
            isNewFilterUXEnabled,
            categoryContentTypeFilterValues,
            categoryFilterValue,
            categoryValues
        });

        const contentTypeFilter =
            categoryContentTypeFilterValues?.map(
                (contentType) => contentType.key
            ) ?? [];

        const updatedUrl = getUpdatedSearchUrl({
            searchValue,
            deployment,
            product,
            version,
            contentTypeFilter
        });

        window.history.replaceState({}, '', updatedUrl);

        const productQueryValue = product ? [product] : [];
        const contentTypeQueryValue =
            contentTypeTab && contentTypeTab !== DEFAULT_CONTENT_TYPE_TAB.key
                ? [contentTypeFilterValue.key]
                : [];

        debouncedQueryHelpAggregator(
            dispatch,
            searchTerm,
            {
                deployment,
                productsQueryValue: productQueryValue,
                contentTypes: isNewFilterUXEnabled
                    ? contentTypeFilterQuery
                    : contentTypeQueryValue,
                version: versionsQueryValue,
                sortByQueryValue,
                pageIndex: currentResultIndex
            },
            searchStartTime,
            isNewFilterUXEnabled
        );
    };

    const handleSortByFilterChange = (value: SortBy) => {
        const oldSortByQueryValue = sortByQueryValue;
        dispatch(setSortByQueryValue(value));
        analytics.trackEvent('Sort by filter', {
            // @ts-ignore
            event: 'clicked',
            eventComponent: 'filter',
            actionSubjectId: 'sort-by-filter',
            category: 'search results filter',
            action: 'clicked',
            currentSelection: oldSortByQueryValue,
            newSelection: value
        });
    };

    useEffect(() => {
        // Avoiding call if the feature flag is not evaluated since this will
        // be called once more once the feature flag is evaluated as it is part of the
        // dependency array (isNewFilterUXEnabled)
        if (!isFeatureFlagEvaluated.current) {
            return;
        }

        handleSubmit(searchTerm);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        deploymentQueryValue,
        productFilterValue,
        contentTypeFilterValue,
        versionsQueryValue,
        sortByQueryValue,
        currentResultIndex,
        categoryContentTypeFilterValues,
        categoryFilterValue,
        isNewFilterUXEnabled,
        currentResultIndex
    ]);

    // Initialize the search query from the url on first visit
    useEffect(() => {
        const parsedUrl = new URL(window.location.href);
        const searchTermFromUrl = parsedUrl.searchParams.get('searchTerm');
        const deploymentFromUrl = parsedUrl.searchParams.get('deployment');
        const productFromUrl = parsedUrl.searchParams.get('product');
        const versionFromUrl = parsedUrl.searchParams.get('version');
        const contentTypeFromUrl = parsedUrl.searchParams.get('contentType');

        const product = entry?.searchOptions?.filters?.platforms
            .filter((platform) => platform.key === deploymentFromUrl)?.[0]
            ?.products.filter((product) => product.key === productFromUrl)?.[0];

        const contentTypeFiltersFromURL: SearchOptionsGenericValue[] =
            contentTypeFromUrl
                ? contentTypeFromUrl
                      .split(',')
                      .map((contentType) => {
                          const foundContentType =
                              entry?.searchOptions?.filters?.categories
                                  .find(({ key }) => key === 'all')
                                  ?.contentTypes.find(
                                      ({ key }) => key === contentType
                                  );
                          return foundContentType as SearchOptionsGenericValue;
                      })
                      .filter(Boolean)
                : [];

        // Batch all the initialization actions
        dispatch(setCategoryContentTypeFilterValues(contentTypeFiltersFromURL));
        dispatch(updateSearchTerm(searchTermFromUrl ?? ''));
        dispatch(
            setProductFilterValue({
                deployment: (deploymentFromUrl as Deployment) || undefined,
                product: {
                    ...product,
                    label: ''
                }
            })
        );
        dispatch(
            setVersionsFilterQueryValue(versionFromUrl ? [versionFromUrl] : [])
        );
        dispatch(saveEntry(entry));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderNewVersionFilter = useCallback(() => {
        if (
            deploymentQueryValue === Deployment.DATA_CENTER &&
            versionsFilterValues?.length > 0
        ) {
            return (
                <>
                    <Divider />
                    <ListFilter
                        title={'Version'}
                        selected={{
                            key: versionsQueryValue[0] || ''
                        }}
                        optionsLists={[{ options: versionsFilterValues }]}
                        isScrollable={true}
                        onChange={(option) => {
                            dispatch(setVersionsFilterQueryValue([option.key]));
                        }}
                        analyticsMetadata={{
                            selectedProduct: productFilterValue.key
                        }}
                    />
                </>
            );
        }
    }, [
        deploymentQueryValue,
        versionsFilterValues,
        versionsQueryValue,
        productFilterValue.key,
        dispatch
    ]);

    const renderSearchResults = useCallback(
        () =>
            searchResponse.results.map((result: any, index: number) => (
                <SearchResultCard
                    key={result.content.contentAri}
                    id={result.content.contentAri}
                    title={result.content.title}
                    description={
                        result?.highlights?.description ||
                        result.content.description
                    }
                    href={result.content.url}
                    dateModified={result.content.metadata.lastPublishedAt}
                    markedUseful={
                        result.content.metadata?.trustFactors?.helpfulCount
                    }
                    views={result.content.metadata?.trustFactors?.numViews}
                    searchTerm={searchTerm}
                    position={index + 1}
                    queryId={searchResponse.queryId}
                    products={result.content.products}
                    productIcons={entry.header.productIcons}
                />
            )),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [searchResponse, entry.header.productIcons]
    );

    const renderProductFilter = useCallback(() => {
        return (
            <ListFilter
                onChange={(option, headerKey) => {
                    dispatch(
                        setProductFilterValue({
                            deployment: headerKey as Deployment,
                            product: option
                        })
                    );
                }}
                title="Product"
                selected={{
                    key: productFilterValue.key,
                    headerKey: deploymentQueryValue
                }}
                optionsLists={productsFilterValues.map((platform) => ({
                    header: {
                        key: platform.key,
                        name: platform.name
                    },
                    options: platform.products.map((product) => {
                        const label = `${product.name} ${
                            isNewFilterUXEnabled
                                ? ''
                                : `(${
                                      searchResponse?.facets?.platform?.[
                                          platform.key
                                      ]?.products?.[product.key]?.count || 0
                                  })`
                        }`;

                        return {
                            ...product,
                            label: label
                        };
                    })
                }))}
            />
        );
    }, [
        deploymentQueryValue,
        dispatch,
        isNewFilterUXEnabled,
        productFilterValue.key,
        productsFilterValues,
        searchResponse.facets
    ]);

    const renderTotalResults = () => {
        const startCount = currentResultIndex + 1;
        return (
            <ResultsText
                searchTerm={searchResponse?.metadata?.query?.originalQuery}
                startCount={startCount}
                endCount={startCount + searchResponse.pagination?.size - 1}
                totalCount={searchResponse.pagination?.total}
            />
        );
    };

    const renderFilterTags = () => {
        const tags = [];
        if (productFilterValue.key) {
            tags.push({
                type: 'product',
                key: productFilterValue.key,
                name: productFilterValue.name,
                analyticsMetadata: {
                    searchTerm,
                    clearedFilterTags: [
                        productFilterValue.key,
                        ...versionsQueryValue
                    ]
                },
                onRemove: () => dispatch(resetProductFilterValue())
            });

            versionsQueryValue.forEach((version) =>
                tags.push({
                    type: 'version',
                    key: version,
                    name: version,
                    analyticsMetadata: {
                        searchTerm,
                        clearedFilterTags: [version]
                    },
                    onRemove: () =>
                        dispatch(
                            setVersionsFilterQueryValue(
                                versionsQueryValue.filter((v) => v !== version)
                            )
                        )
                })
            );
        }

        if (categoryContentTypeFilterValues.length > 0) {
            categoryContentTypeFilterValues.forEach((contentType) =>
                tags.push({
                    type: 'contentType',
                    key: contentType.key,
                    name: contentType.name,
                    analyticsMetadata: {
                        searchTerm,
                        clearedFilterTags: [contentType.key]
                    },
                    onRemove: () =>
                        dispatch(
                            setCategoryContentTypeFilterValues(
                                categoryContentTypeFilterValues.filter(
                                    ({ key }) => key !== contentType.key
                                )
                            )
                        )
                })
            );
        }

        return <FilterTags tags={tags} />;
    };

    const renderPagination = () => {
        return (
            <div className="pagination" data-testid="search-results-pagination">
                <Pagination
                    testId="pagination"
                    pages={
                        searchResponse.pagination
                            ? Array.from(
                                  {
                                      length: Math.min(
                                          Math.ceil(
                                              searchResponse.pagination?.total /
                                                  NUM_OF_RESULTS_PER_PAGE
                                          ),
                                          MAX_NUM_OF_RESULTS /
                                              NUM_OF_RESULTS_PER_PAGE
                                      )
                                  },
                                  (_, i) => i + 1
                              )
                            : []
                    }
                    selectedIndex={Math.floor(
                        currentResultIndex / NUM_OF_RESULTS_PER_PAGE
                    )}
                    onChange={(
                        _: React.SyntheticEvent<Element, Event>,
                        page: number
                    ) => {
                        const oldPage =
                            Math.floor(
                                currentResultIndex / NUM_OF_RESULTS_PER_PAGE
                            ) + 1;

                        if (oldPage !== page) {
                            const newResultIndex =
                                (page - 1) * NUM_OF_RESULTS_PER_PAGE;
                            dispatch(setCurrentResultIndex(newResultIndex));
                            analytics.trackEvent('pagination', {
                                // @ts-ignore
                                event: 'clicked',
                                eventComponent: 'pagination',
                                actionSubjectId: 'pagination',
                                category: 'pagination',
                                action: 'clicked',
                                currentSelection: oldPage,
                                newSelection: page
                            });
                        }
                    }}
                />
            </div>
        );
    };

    const renderCategoryTabs = useCallback(() => {
        const tabs: ContentTypeTab[] = [];

        categoryValues.forEach((categoryType) => {
            tabs.push({
                key: categoryType.key,
                name: categoryType.name,
                label: `${categoryType.name}`,
                testId: `${categoryType.key}-tab`
            });
        });

        const handleTabSelect = (selectedCategoryType: ContentTypeTab) => {
            const previousValue = categoryFilterValue.key;
            dispatch(setCategoryFilterValue(selectedCategoryType));
            return previousValue;
        };

        return (
            <ContentTypeTabs
                tabs={tabs}
                onSelect={handleTabSelect}
                testId="category-type-tabs"
            />
        );
    }, [categoryValues, categoryFilterValue.key, dispatch]);

    const renderContentTypeTabs = useCallback(() => {
        const tabs: ContentTypeTab[] = [];
        let totalDocCount = 0;
        contentTypeValues?.forEach((contentType) => {
            const docCount =
                searchResponse?.facets?.contentType?.[contentType.key]?.count ||
                0;
            totalDocCount += docCount;
            tabs.push({
                key: contentType.key,
                name: contentType.name,
                label: `${contentType.name} (${docCount})`,
                testId: `${contentType.key}-tab`,
                docCount
            });
        });

        tabs.unshift({
            ...DEFAULT_CONTENT_TYPE_TAB,
            label: `${DEFAULT_CONTENT_TYPE_TAB.name} (${totalDocCount})`,
            docCount: totalDocCount
        });

        const handleTabSelect = (selectedContentType: ContentTypeTab) => {
            const previousValue = contentTypeFilterValue.key;
            dispatch(setContentTypeFilterValue(selectedContentType));
            return previousValue;
        };

        return <ContentTypeTabs tabs={tabs} onSelect={handleTabSelect} />;
    }, [
        contentTypeValues,
        searchResponse.facets,
        contentTypeFilterValue.key,
        dispatch
    ]);

    const renderContentTypeFilter = useCallback(() => {
        const selectedCategory = categoryValues.find(
            (category) => category.key === categoryFilterValue.key
        );

        const contentTypeOptions: SearchOptionsGenericValue[] =
            selectedCategory?.contentTypes?.map((contentType) => ({
                key: contentType.key,
                name: contentType.name
            })) || [];

        return (
            <MultiSelectListFilter
                title="Content Type"
                selected={categoryContentTypeFilterValues}
                optionsLists={[
                    {
                        options: contentTypeOptions
                    }
                ]}
                onChange={(selected) => {
                    dispatch(setCategoryContentTypeFilterValues(selected));
                }}
            />
        );
    }, [
        categoryValues,
        categoryContentTypeFilterValues,
        categoryFilterValue.key,
        dispatch
    ]);

    const subHeaderText = translatableText.heroBannerSubheader.text;

    const placeholder = translatableText.searchPlaceholderV2.text;

    useEffect(() => {
        const getFeatureFlagStatus = async () => {
            const isNewFilterUxEnabledForSacExperiment = await getExperiment(
                'sac_new_sources_updated_ux_experiment'
            );

            const isNewFilterUxEnabledForSac =
                isNewFilterUxEnabledForSacExperiment.getValue(
                    'new_ux_enabled',
                    STATSIG_EXPERIMENTS_DEFAULTS[
                        'sac-new-content-ux-experiment-gate'
                    ]['new_ux_enabled']
                ) as boolean;

            setIsNewFilterUXEnabled(isNewFilterUxEnabledForSac);

            setTopTabs(
                isNewFilterUxEnabledForSac
                    ? renderCategoryTabs()
                    : renderContentTypeTabs()
            );

            setProductFilter(renderProductFilter());
            setVersionFilter(renderNewVersionFilter());
            isFeatureFlagEvaluated.current = true;
        };
        getFeatureFlagStatus();
    }, [
        productsFilterValues,
        versionsFilterValues,
        categoryValues,
        contentTypeValues,
        renderProductFilter,
        renderNewVersionFilter,
        renderCategoryTabs,
        renderContentTypeTabs
    ]);

    const renderSearchResultArea = useCallback(() => {
        if (isLoading && !!searchTerm) {
            return (
                <div className="search-results-spinner-container">
                    <Spinner size={'xlarge'} />
                </div>
            );
        } else if (searchResponse?.queryId && searchResponse.results?.length) {
            return renderSearchResults();
        } else {
            return (
                <NoSearchResults
                    searchTerm={searchTerm}
                    isNewFilterUXEnabled={isNewFilterUXEnabled}
                />
            );
        }
    }, [
        isLoading,
        searchResponse,
        searchTerm,
        renderSearchResults,
        isNewFilterUXEnabled
    ]);

    return (
        <div className="search-results" data-testid="page-template">
            <div className="content-wrapper">
                <Header
                    id={entry.header.id}
                    pageId={entry.id}
                    resources={entry.resources}
                    additionalLinks={entry.header.additionalLinks}
                    atlassianLogo={entry.header.atlassianLogo}
                    atlassianSupportLogoV2={entry.header.atlassianSupportLogoV2}
                    atlassianSupportLogoV3={entry.header.atlassianSupportLogoV3}
                    logo={entry.header.atlassianSupportLogo.url}
                    showPreviewBanner={entry.preview}
                    atlassianSupportLogo={entry.header.atlassianSupportLogo}
                    serverProducts={entry.header.serverProducts}
                    cloudProducts={entry.header.cloudProducts}
                    menus={entry.header.menus}
                    isNavRedesignEnabled={isNavRedesignEnabled}
                    renderV2={true}
                />
                <main id="maincontent">
                    <HeroBanner
                        searchField={
                            <Search
                                onSearch={handleSubmit}
                                placeholder={placeholder}
                                initialValue={searchTerm}
                                analyticsMetadata={{
                                    parentComponent: 'search-results'
                                }}
                                onChange={handleSearchChange}
                            />
                        }
                        subHeader={subHeaderText}
                        {...(isAiEnabled ? { appearance: 'minimal' } : {})}
                    />
                    <div
                        data-testid="search-results-page"
                        className="search-results-page"
                    >
                        <div className="above-search-cards-container">
                            <div className="content-type-tabs">{topTabs}</div>
                            <div className="search-results-summary">
                                {!isLoading && renderTotalResults()}
                            </div>
                            <div className="sort-by-filter-section">
                                <div className="sort-by-filter-text">
                                    {translatableText.sortBy.text}
                                </div>
                                <FilterSelector
                                    filterTitle={translatableText.sortBy.text}
                                    filterValues={sortByFilterValues}
                                    allowFilterAll={false}
                                    setQueryValues={handleSortByFilterChange}
                                    queryByString
                                    defaultSelected={sortByFilterValues[0].name}
                                    showBorder
                                />
                            </div>
                        </div>
                        <div className="search-results-grid">
                            <div className="search-filters">
                                <ResetButton
                                    appearance="default"
                                    analyticsMetadata={{
                                        searchTerm,
                                        clearedDeployment: deploymentQueryValue,
                                        clearedProducts: [
                                            productFilterValue.key
                                        ],
                                        clearedVersions: versionsQueryValue,
                                        clearedContentTypes:
                                            categoryContentTypeFilterValues.map(
                                                ({ key }) => key
                                            )
                                    }}
                                    onReset={() => {
                                        const shouldReset =
                                            !!productFilterValue.key ||
                                            categoryContentTypeFilterValues.length >
                                                0;
                                        if (shouldReset) {
                                            dispatch(resetAllValues());
                                        }
                                        return shouldReset;
                                    }}
                                />
                                <Divider />
                                {renderFilterTags()}
                                <Divider />
                                {isNewFilterUXEnabled &&
                                    renderContentTypeFilter()}
                                {productFilter}
                                {versionFilter}
                            </div>
                            <SearchResultsDisplay>
                                {searchResponse?.queryId &&
                                    searchResponse.results?.length !== 0 && (
                                        <SearchAIContainer
                                            searchTerm={
                                                searchResponse?.metadata?.query
                                                    ?.originalQuery
                                            }
                                        />
                                    )}
                                {renderSearchResultArea()}
                            </SearchResultsDisplay>
                        </div>
                        {showPagination && renderPagination()}
                    </div>
                </main>
            </div>
            <Footer
                logo={entry.header.atlassianLogo.url}
                className={'margin-top-large'}
            />
        </div>
    );
};
