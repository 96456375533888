import { capitalize } from 'lodash';
import { ORDERED_KB_ARTICLE_SECTIONS } from '../../../../renderer/components/kb-article-rtf-content/kb-article-rtf-content';
import {
    GqlKbArticleBody,
    KbStandardArticleBody
} from '../../../types/content/kb-article';
import { transformMigratedArticleBody } from './transform-migrated-kb-article-body';

const getTableOfContents = (sections: KbStandardArticleBody['sections']) => {
    return ORDERED_KB_ARTICLE_SECTIONS.filter(
        (textField) => !!sections[textField]
    ).map((textField) => ({
        id: textField,
        title: capitalize(textField)
    }));
};

export function getBodyAndTableOfContents(articleBody: GqlKbArticleBody) {
    // migrated KB article: transform rich text body
    if (articleBody.type === 'KbArticleMigrated') {
        const { document, tableOfContents } = transformMigratedArticleBody({
            document: articleBody.body.json
        });

        return {
            body: {
                type: articleBody.type,
                body: {
                    ...articleBody.body,
                    json: document
                }
            },
            tableOfContents
        };
    }

    // standard KB article: leave rich text sections "as is"
    const tableOfContents = getTableOfContents(articleBody);
    return {
        body: {
            type: articleBody.type,
            sections: {
                summary: articleBody.summary,
                diagnosis: articleBody.diagnosis,
                cause: articleBody.cause,
                solution: articleBody.solution
            }
        },
        tableOfContents
    };
}
