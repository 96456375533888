import React, { useEffect, useState } from 'react';
import Modal, {
    ModalTitle,
    ModalHeader,
    ModalBody,
    ModalFooter
} from '@atlaskit/modal-dialog';
import Button, { ButtonGroup } from '@atlaskit/button';
import get from 'lodash/get';
import './version-selector-modal.less';
import {
    translatableText
    // @ts-ignore
} from '../../utils/translatable-text';
import { Deployment } from '../../pages/search-results/types';

const PRODUCT_NAME_ID_SELECTOR = 'meta[name="product-name-id"]';
const PAGE_NAME_SELECTOR = 'meta[itemprop="name"]';

const VersionSelectorModalFooter = ({
    searchParams,
    spaceId,
    close
}: {
    searchParams: string;
    spaceId: string;
    close: () => void;
}) => (
    <div className="version-modal__footer">
        <ButtonGroup>
            <Button href={`/search-results/?${searchParams}`} target="_self">
                {translatableText.search.text}
            </Button>
            <Button
                href={`http://confluence.atlassian.com/display/${spaceId}`}
                target="_self"
            >
                {translatableText.browse.text}
            </Button>
            <Button appearance="subtle" className="cancel" onClick={close}>
                {translatableText.cancel.text}
            </Button>
        </ButtonGroup>
    </div>
);

export const VersionSelectorModal = ({
    version,
    close,
    spaceId
}: {
    version: string;
    close: () => void;
    spaceId: string;
}) => {
    const [searchParams, setSearchParams] = useState('');

    useEffect(() => {
        const productDeployment = Deployment.DATA_CENTER; // Only Data Center products have versions
        const pageName = get(
            document.querySelector(PAGE_NAME_SELECTOR),
            'content',
            ''
        );
        const productNameId = get(
            document.querySelector(PRODUCT_NAME_ID_SELECTOR),
            'content',
            ''
        );

        const queryParams = [];
        queryParams.push(`searchTerm=${encodeURIComponent(pageName)}`);
        queryParams.push(`deployment=${encodeURIComponent(productDeployment)}`);
        productNameId &&
            queryParams.push(`product=${encodeURIComponent(productNameId)}`);
        version && queryParams.push(`version=${encodeURIComponent(version)}`);

        setSearchParams(queryParams.join('&'));
    }, [version]);

    return (
        <Modal width="small">
            <ModalHeader>
                <ModalTitle>Unable to find page</ModalTitle>
            </ModalHeader>
            <ModalBody>
                <p
                    className="version-modal__body"
                    data-testid="version-modal-body"
                >
                    This exact page does not exist in our Data Center {version}{' '}
                    documentation. Would you like to look for an alternative
                    page?
                </p>
            </ModalBody>
            <ModalFooter>
                <VersionSelectorModalFooter
                    searchParams={searchParams}
                    spaceId={spaceId}
                    close={close}
                />
            </ModalFooter>
        </Modal>
    );
};
