import { LOGIN_ATLASSIAN, APPLICATION_NAME } from '../renderer/utils/constants';

export function addCurrentEvnToDomain(targetUrl: string, host: string): string {
    const url = new URL(targetUrl);
    const [sub, domain, extension] = url.host.split('.');
    if (host.includes('.stg.')) {
        url.host = `${sub}.stg.internal.${domain}.${extension}`;
    }
    if (host.includes('.dev.')) {
        url.host = `${sub}.dev.internal.${domain}.${extension}`;
    }

    return url.href;
}

// WHY? read this: https://developer.atlassian.com/platform/identity/learning/sso/integrate-with-account-switcher/#synchronize-accounts
export function wrapUrlWithLogin({
    url,
    host,
    email,
    prompt
}: {
    url: string;
    host: string;
    prompt: string;
    email?: string;
}): string {
    // login URL should be adjusted to include the current environment
    const loginUrlWithEnv = addCurrentEvnToDomain(LOGIN_ATLASSIAN, host);

    // add params to the login URL
    const resultURL = new URL(loginUrlWithEnv);
    resultURL.searchParams.set('prompt', prompt);
    resultURL.searchParams.set('application', APPLICATION_NAME);
    resultURL.searchParams.set('continue', encodeURI(url));
    if (email) {
        resultURL.searchParams.set('login_hint', encodeURI(email));
    }

    /** EXAMPLE:
    https://id.atlassian.com/login?application=bitbucket&continue=https%3A%2F%2Fid.atlassian.com%2Fmanage-profile&login_hint=foo%40bar.com&prompt=none.
     */
    return resultURL.href;
}
