import {
    AtlassianIcon,
    BitbucketIcon,
    ConfluenceIcon,
    HipchatIcon,
    JiraCoreIcon,
    JiraServiceDeskIcon,
    JiraSoftwareIcon,
    JiraIcon,
    StatuspageIcon,
    StrideIcon
} from '@atlaskit/logo';

// the @atlaskit/logo and @atlaskit/icon packages use different size parameters to
// achieve the same size. Including size parameter here with data.
const logoSize = 'xsmall';

export default {
    AtlassianIcon: {
        componentName: 'AtlassianIcon',
        component: AtlassianIcon,
        keywords: ['product', 'logo', 'atlassian'],
        package: '@atlaskit/logo/dist/esm/AtlassianLogo/Icon',
        size: logoSize
    },
    BitbucketIcon: {
        componentName: 'BitbucketIcon',
        component: BitbucketIcon,
        keywords: ['product', 'logo', 'bitbucket'],
        package: '@atlaskit/logo/dist/esm/BitbucketLogo/Icon',
        size: logoSize
    },
    ConfluenceIcon: {
        componentName: 'ConfluenceIcon',
        component: ConfluenceIcon,
        keywords: ['product', 'logo', 'confluence'],
        package: '@atlaskit/logo/dist/esm/ConfluenceLogo/Icon',
        size: logoSize
    },
    HipchatIcon: {
        componentName: 'HipchatIcon',
        component: HipchatIcon,
        keywords: ['product', 'logo', 'hipchat'],
        package: '@atlaskit/logo/dist/esm/HipchatLogo/Icon',
        size: logoSize
    },
    JiraCoreIcon: {
        componentName: 'JiraCoreIcon',
        component: JiraCoreIcon,
        keywords: ['product', 'logo', 'jira', 'core'],
        package: '@atlaskit/logo/dist/esm/JiraCoreLogo/Icon',
        size: logoSize
    },
    JiraServiceDeskIcon: {
        componentName: 'JiraServiceDeskIcon',
        component: JiraServiceDeskIcon,
        keywords: ['product', 'logo', 'jira', 'servicedesk'],
        package: '@atlaskit/logo/dist/esm/JiraServiceDeskLogo/Icon',
        size: logoSize
    },
    JiraSoftwareIcon: {
        componentName: 'JiraSoftwareIcon',
        component: JiraSoftwareIcon,
        keywords: ['product', 'logo', 'jira', 'software'],
        package: '@atlaskit/logo/dist/esm/JiraSoftwareLogo/Icon',
        size: logoSize
    },
    JiraIcon: {
        componentName: 'JiraIcon',
        component: JiraIcon,
        keywords: ['product', 'logo', 'jira'],
        package: '@atlaskit/logo/dist/esm/JiraLogo/Icon',
        size: logoSize
    },
    StatuspageIcon: {
        componentName: 'StatuspageIcon',
        component: StatuspageIcon,
        keywords: ['product', 'logo', 'statuspage'],
        package: '@atlaskit/logo/dist/esm/StatuspageLogo/Icon',
        size: logoSize
    },
    StrideIcon: {
        componentName: 'StrideIcon',
        component: StrideIcon,
        keywords: ['product', 'logo', 'stride'],
        package: '@atlaskit/logo/dist/esm/StrideLogo/Icon',
        size: logoSize
    }
};
