import createEmotionServer, {
    EmotionServer
} from '@emotion/server/create-instance';
import createCache, { EmotionCache } from '@emotion/cache';

const EMOTION_CACHE_KEY = 'emotion-sac';

let emotionCache: {
    cache: EmotionCache;
    emotionServer: EmotionServer;
};

export const getEmotionCache = () => {
    if (!emotionCache) {
        const cache = createCache({ key: EMOTION_CACHE_KEY });
        const emotionServer = createEmotionServer(cache);
        emotionCache = {
            cache,
            emotionServer
        };
    }

    return emotionCache;
};
