import React, { useState } from 'react';

import './list-filter.less';
import { Radio } from '@atlaskit/radio';
import Button from '@atlaskit/button';
import ChevronUpIcon from '@atlaskit/icon/glyph/chevron-up';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import analytics from '../../utils/analytics';
import {
    SearchOptionsGenericValue,
    SearchOptionsProductValue
} from '../../pages/search-results/types';

export interface ListFilterOption extends SearchOptionsProductValue {
    label: string;
}

interface ListFilterProps {
    title: string;
    selected: {
        key: string;
        headerKey?: string;
    };
    optionsLists: {
        header?: SearchOptionsGenericValue;
        options: ListFilterOption[];
    }[];
    isScrollable?: boolean;
    onChange: (option: ListFilterOption, headerKey?: string) => void;
    analyticsMetadata?: object;
}

interface GetChevronIconProps {
    title: string;
    showFilter: boolean;
    setShowFilter: (showFilter: boolean) => void;
}

const getChevronIcon = ({
    title,
    showFilter,
    setShowFilter
}: GetChevronIconProps) => {
    const label = showFilter
        ? `Collapse ${title} filters`
        : `Expand ${title} filters`;

    const icon = showFilter ? (
        <ChevronUpIcon size="large" label="" />
    ) : (
        <ChevronDownIcon size="large" label="" />
    );

    return (
        <Button
            testId="list-filter__chevron"
            className="list-filter__chevron"
            appearance="subtle"
            label={label}
            iconAfter={icon}
            onClick={() => setShowFilter(!showFilter)}
        />
    );
};

export const ListFilter = ({
    title,
    optionsLists,
    selected,
    onChange,
    isScrollable = false,
    analyticsMetadata = {}
}: ListFilterProps) => {
    const [showFilter, setShowFilter] = useState<boolean>(true);
    return (
        <div className="list-filter" data-testid={`list-filter-${title}`}>
            <div
                className="list-filter__heading"
                data-testid="list-filter__heading"
            >
                <span className="list-filter__title">
                    {title.toUpperCase()}
                </span>
                {getChevronIcon({ title, showFilter, setShowFilter })}
            </div>
            {showFilter && (
                <div
                    data-testid="list-filter__options-list__container"
                    className={`list-filter__options-list__container ${
                        isScrollable
                            ? 'list-filter__options-list__container__scrollable'
                            : ''
                    }`}
                >
                    {optionsLists.map((optionsList) => (
                        <div
                            key={optionsList?.header?.key ?? 'default'}
                            className="list-filter__options-list"
                            data-testid={`list-filter__options-list-${
                                optionsList?.header?.key ?? 'default'
                            }`}
                        >
                            {optionsList?.header?.name && (
                                <div className="list-filter__header">
                                    {optionsList.header.name}
                                </div>
                            )}
                            {optionsList.options.map((option) => (
                                <div
                                    key={option.key}
                                    className="list-filter__radio"
                                    data-testid="list-filter__radio"
                                    data-value={
                                        option.key === selected.key &&
                                        optionsList?.header?.key ===
                                            selected?.headerKey
                                    }
                                >
                                    <Radio
                                        key={option.key}
                                        name={option.key}
                                        value={option.key}
                                        label={option.label}
                                        testId={`list-filter__radio-${option.key}`}
                                        isChecked={
                                            option.key === selected.key &&
                                            optionsList?.header?.key ===
                                                selected?.headerKey
                                        }
                                        onChange={() => {
                                            onChange(
                                                option,
                                                optionsList?.header?.key
                                            );
                                            analytics.trackEvent(
                                                'list filter',
                                                {
                                                    // @ts-ignore
                                                    event: 'selected',
                                                    eventComponent: 'radio',
                                                    actionSubjectId:
                                                        'listFilter',
                                                    category: 'filter',
                                                    action: 'selected',
                                                    label: 'list filter',
                                                    filterTitle: title,
                                                    selectedFilter: option.key,
                                                    filterList:
                                                        optionsList.options.map(
                                                            (option) =>
                                                                option.key
                                                        ),
                                                    ...(optionsList?.header
                                                        ?.key && {
                                                        filterHeader:
                                                            optionsList.header
                                                                .key
                                                    }),
                                                    ...analyticsMetadata
                                                }
                                            );
                                        }}
                                        onPointerEnterCapture={() => {}}
                                        onPointerLeaveCapture={() => {}}
                                    />
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
