import './featured-content.less';
import React from 'react';
import {
    FeaturedContent as FeaturedContentEntry,
    FeaturedEntry
} from '../../../content/types/content/featured-content';
import { AtlasLink } from '../atlas-link';
import analytics from '../../utils/analytics';
import { getContentAri } from '../../utils/get-content-ari';
import { AnalyticsMetadata } from '../../utils/analytics/types';

interface FeaturedContentProps {
    content: FeaturedContentEntry;
    analyticsMetadata: AnalyticsMetadata;
}

interface FeaturedContentListProps {
    entries: FeaturedEntry[];
    analyticsMetadata: AnalyticsMetadata;
}

interface FeaturedContentListItemProps {
    entry: FeaturedEntry;
    position: number;
    analyticsMetadata: AnalyticsMetadata;
}

const FeaturedContentListItem = ({
    entry,
    position,
    analyticsMetadata
}: FeaturedContentListItemProps) => {
    return (
        <div className={`featured-content-list-item`}>
            <AtlasLink
                className={`featured-content-list-item__link`}
                href={entry.url}
                text={entry.linkText}
                target={entry.linkTarget}
                onClick={() => {
                    analytics.trackEvent('featured article', {
                        // @ts-ignore
                        event: 'clicked',
                        eventComponent: 'featured-content-list-item',
                        actionSubjectId: 'link',
                        category: 'featured-content',
                        action: 'clicked',
                        label: 'featured article',
                        position,
                        linkUrl: entry.url,
                        linkText: entry.linkText,
                        linkTarget: entry.linkTarget,
                        contentId: entry.id,
                        linkContentAri: getContentAri(entry.id),
                        contentType: entry.type,
                        ...analyticsMetadata
                    });
                }}
            />
        </div>
    );
};

const FeaturedContentList = ({
    entries,
    analyticsMetadata
}: FeaturedContentListProps) => {
    return (
        <div className={`featured-content-list`}>
            {entries.map((entry, index) => (
                <FeaturedContentListItem
                    key={entry.id}
                    entry={entry}
                    position={index + 1}
                    analyticsMetadata={analyticsMetadata}
                />
            ))}
        </div>
    );
};

export const FeaturedContent = ({
    content,
    analyticsMetadata
}: FeaturedContentProps) => {
    return (
        <section className={`featured-content`}>
            <div className={`featured-content__inner`}>
                <div className={`featured-content__header`}>
                    <h2 className={`featured-content__header-title`}>
                        {content.title}
                    </h2>
                </div>
                <FeaturedContentList
                    entries={content.featuredEntries}
                    analyticsMetadata={analyticsMetadata}
                />
            </div>
        </section>
    );
};
