/* eslint-disable react/prop-types */
import React from 'react';
import { AkCodeBlock } from '@atlaskit/code';
import {
    getCodeLanguage,
    type GqlComponentCodeBlock
} from '../../../../content/types/content/component-code-block';

interface ComponentCodeBlockProps {
    entry: GqlComponentCodeBlock;
}

const ComponentCodeBlock: React.FC<ComponentCodeBlockProps> = ({ entry }) => {
    const { code, language, title } = entry;

    return (
        <>
            {title && (
                <p>
                    <strong>{title}</strong>
                </p>
            )}
            <div className="kb-article-code-block">
                <AkCodeBlock text={code} language={getCodeLanguage(language)} />
            </div>
        </>
    );
};

export default ComponentCodeBlock;
