import React from 'react';
import { HeroBanner } from '../../components/hero-banner';
import { Search, handleOnSearch } from '../../components/search';
import { Header } from '../../components/header';
import FooterV2 from '../../components/footer-v2';
import { FeaturedContent } from '../../components/featured-content';
import { ContactCtaBlock } from '../../components/contact';
import EasterEgg from '../../components/easter-egg';
import { HomePageType } from './home.type';
import './home.less';
import { translatableText } from '../../utils/translatable-text';
import { PlatformTabs } from '../../components/platform-tabs';
import { CardSection } from '../../components/card-section';
import PropTypes from 'prop-types';

const SACHomePage = (props) => {
    const {
        entry: {
            cards,
            featuredResources,
            featuredContent,
            header,
            cloudProducts,
            serverProducts,
            id,
            preview
        },
        featureFlags: { isNavRedesignEnabled }
    } = props;

    const combinedCards = [...featuredResources.cards, ...cards];

    return (
        <div className={`home-v2`} data-testid="page-template">
            <div className={`content-wrapper-v2`}>
                <Header
                    cloudProducts={header.cloudProducts}
                    serverProducts={header.serverProducts}
                    logo={header.atlassianSupportLogo.url}
                    atlassianSupportLogo={header.atlassianSupportLogo}
                    atlassianSupportLogoV2={header.atlassianSupportLogoV2}
                    atlassianSupportLogoV3={header.atlassianSupportLogoV3}
                    id={header.id}
                    additionalLinks={header.additionalLinks}
                    resources={featuredResources}
                    showPreviewBanner={preview}
                    pageId={id}
                    isNavRedesignEnabled={isNavRedesignEnabled}
                    menus={header.menus}
                    renderV2={true}
                />
                <main id="maincontent">
                    <HeroBanner
                        searchField={
                            <Search
                                onSearch={handleOnSearch}
                                placeholder="Tell us what you're looking for"
                                analyticsMetadata={{
                                    parentComponent: 'hero-banner'
                                }}
                            />
                        }
                        subHeader={translatableText.heroBannerSubheader.text}
                    />

                    <div className="main-content-container">
                        {featuredContent?.featuredEntries?.length && (
                            <div
                                className={`home-v2__featured-content`}
                                data-testid={`home-v2-featured-content`}
                            >
                                <div
                                    className={`home-v2__featured-content--inner`}
                                >
                                    <FeaturedContent
                                        content={featuredContent}
                                        analyticsMetadata={{
                                            parentComponent: 'home'
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                        <div className={`home-v2__browse`}>
                            <div
                                className={`home-v2__browse--inner platform-tabs-wrapper`}
                            >
                                <h2 className={`home-v2__header`}>
                                    {translatableText.browseByProduct.text}
                                </h2>
                                <PlatformTabs
                                    cloudProducts={cloudProducts}
                                    serverProducts={serverProducts}
                                    details="home page"
                                    analyticsMetadata={{
                                        parentComponent: 'browse-by-product'
                                    }}
                                />
                            </div>
                        </div>
                        <div className={`home-v2__browse`}>
                            <div className={`home-v2__browse--inner`}>
                                <h2 className={`home-v2__header`}>
                                    {translatableText.browseResources.text}
                                </h2>
                                <CardSection cards={combinedCards} />
                            </div>
                        </div>
                        <div className={`home-v2__browse`}>
                            <div className={`home-v2__browse--inner`}>
                                <ContactCtaBlock
                                    analyticsMetadata={{
                                        parentComponent: 'home'
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            <FooterV2
                analyticsMetadata={{
                    parentComponent: 'home'
                }}
            />
            <EasterEgg />
        </div>
    );
};

SACHomePage.defaultProps = {};

SACHomePage.propTypes = {
    entry: HomePageType,
    featureFlags: PropTypes.shape({
        isNavRedesignEnabled: PropTypes.bool
    })
};

export default SACHomePage;
