import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import renderReactApp from '../templating/render-react-app';
import { createBrowserHistory } from 'history';
import { CacheProvider } from '@emotion/react';
import { getEmotionCache } from '../templating/emotion-cache';

// TODO: yarn add @atlaskit/css-reset and uncomment when we migrate to using @atlaskit/tokens (Atlaskit Design Tokens)
// import '@atlaskit/css-reset';

export const initializeBrowserApp = () => {
    // hashbang routes are being used so that S3 will still serve the
    // myRequests app at 'requests/index.html'. On load, the hash
    // path is converted back to a normal path so that it can be
    // used for dynamic routing inside the myRequests app.
    const history = createBrowserHistory();
    const hashbangPath = (/#!\/(.+)$/.exec(history.location.hash) || [])[1];
    const isRequestsRoute = /^\/requests/.exec(history.location.pathname);

    if (isRequestsRoute && hashbangPath) {
        const newPathname = history.location.pathname + hashbangPath;
        history.replace({
            pathname: newPathname,
            hash: ''
        });
    }

    // setGlobalTheme({ colorMode: 'light' });

    const { cache } = getEmotionCache();
    const reactApp = (
        // <React.StrictMode>
        <CacheProvider value={cache}>
            {renderReactApp(
                window.__APP_INITIAL_STATE__.entry,
                window.__APP_INITIAL_STATE__.pageTree,
                window.__APP_INITIAL_STATE__.featureFlags
            )}
        </CacheProvider>
        // </React.StrictMode>
    );

    hydrateRoot(document.querySelector('#root'), reactApp);
};
