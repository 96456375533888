import React from 'react';
import './no-search-results.less';
import { translatableText } from '../../utils/translatable-text';
import NoResultFoundSVG from './no-result-found-svg';

interface NoSearchResultsProps {
    searchTerm: string;
    isNewFilterUXEnabled: boolean;
}

export const NoSearchResults = ({
    searchTerm,
    isNewFilterUXEnabled
}: NoSearchResultsProps) => {
    const noResultsText = searchTerm
        ? `${translatableText.noSearchResults.text} "${searchTerm}".`
        : translatableText.noSearchResults.text.replace(' for', '.');

    const newNoResultsText = translatableText.noSearchResults.text.replace(
        ' for',
        ''
    );

    return isNewFilterUXEnabled ? (
        <div
            data-testid="no-search-results"
            className="no-results-text-container"
        >
            <div className="no-results-svg">
                <NoResultFoundSVG />
            </div>
            <h2 className="no-results-text-heading">{newNoResultsText}</h2>
            <p className="no-results-text-subheading">
                {translatableText.noSearchResultsSubheading.text}
            </p>
        </div>
    ) : (
        <span data-testid="no-search-results" className="no-results-text">
            {noResultsText}
        </span>
    );
};
