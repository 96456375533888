import React, { useEffect, useState } from 'react';
import DropdownMenu, {
    DropdownItemGroup,
    DropdownItem
} from '@atlaskit/dropdown-menu';
import './filter-selector.less';
import { SearchOptionsGenericValue } from '../types';

export interface FilterProps {
    filterTitle: string;
    filterValues: SearchOptionsGenericValue[];
    setQueryValues: any;
    allowFilterAll?: boolean;
    queryByString?: boolean;
    titleReset?: boolean;
    setTitleReset?: any;
    defaultSelected?: string;
    shouldPulse?: boolean;
    setShouldPulse?: any;
    showBorder?: boolean;
}

const FilterSelector = ({
    filterTitle,
    filterValues,
    setQueryValues,
    allowFilterAll = true,
    queryByString = false,
    titleReset,
    setTitleReset,
    defaultSelected,
    shouldPulse = false,
    setShouldPulse,
    showBorder = false
}: FilterProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState(defaultSelected ?? filterTitle);

    useEffect(() => {
        setSelected(defaultSelected ?? filterTitle);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultSelected]);

    // Edge case for when the title needs to be reset (used in product/deployment coupled filters)
    useEffect(() => {
        if (titleReset) {
            setSelected(defaultSelected ?? filterTitle);
            setTitleReset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [titleReset, setTitleReset]);

    const classes = ['dropdown-container'];
    if (showBorder) {
        classes.push('dropdown-container_border');
    }
    if (shouldPulse) {
        classes.push('dropdown-container__pulse');
    }

    const testId = `filter-selector-${filterTitle
        .replace(/ /g, '-')
        .toLowerCase()}`;

    return (
        <div className={classes.join(' ')} data-testid={testId}>
            <DropdownMenu
                trigger={selected}
                // triggerType="button"
                isOpen={isOpen}
                onOpenChange={({
                    isOpen: isOpenedNow
                }: {
                    isOpen: boolean;
                }) => {
                    setIsOpen(isOpenedNow);
                }}
                testId={testId}
            >
                <DropdownItemGroup title={filterTitle}>
                    {allowFilterAll && (
                        <DropdownItem
                            key={`All-${filterTitle}s`}
                            onClick={() => {
                                setSelected(`All ${filterTitle}s`);
                                if (setShouldPulse) {
                                    setShouldPulse();
                                }
                                setQueryValues(
                                    filterValues.map(({ key }) => key)
                                );
                            }}
                            testId=""
                        >
                            {`All ${filterTitle}s`}
                        </DropdownItem>
                    )}
                    {filterValues.map(({ name, key }) => (
                        <DropdownItem
                            key={name}
                            onClick={() => {
                                setSelected(name);
                                if (setShouldPulse) {
                                    setShouldPulse();
                                }
                                setQueryValues(queryByString ? key : [key]);
                            }}
                        >
                            {name}
                        </DropdownItem>
                    ))}
                </DropdownItemGroup>
            </DropdownMenu>
        </div>
    );
};

export default FilterSelector;
