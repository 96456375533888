import metadata from '@atlaskit/icon/metadata';
import isEmpty from 'lodash/isEmpty';

const iconMetadata = {};

export default async () => {
    if (!isEmpty(iconMetadata)) {
        return iconMetadata;
    }
    await Promise.all(
        Object.keys(metadata).map(async (key) => {
            const packageName = metadata[key].package.replace(
                '@atlaskit/icon/glyph/',
                ''
            );
            const component = await import(
                `@atlaskit/icon/glyph/${packageName}.js`
            );
            iconMetadata[key] = {
                ...metadata[key],
                component: component.default,
                // using size 'small' to fit within normal paragraph line-height
                size: 'small'
            };
        })
    );
    return iconMetadata;
};
