import React, { ReactElement } from 'react';
import { translatableText } from '../../utils/translatable-text';
import './hero-banner.less';
import { Search } from '../search';

export enum Appearance {
    FULL = 'full',
    MINIMAL = 'minimal'
}

interface Props {
    searchField: ReactElement<typeof Search>;
    subHeader?: string;
    appearance?: 'full' | 'minimal';
}

export const HeroBanner = (props: Props) => {
    const { searchField, subHeader, appearance = Appearance.FULL } = props;

    const baseClass = appearance === Appearance.MINIMAL ? '-minimal' : '-full';

    return (
        <section
            className={`hero-banner${baseClass}`}
            data-testid="hero_banner"
        >
            <div
                data-testid="hero_inner"
                className={`hero-banner${baseClass}__inner`}
            >
                {appearance === Appearance.FULL && (
                    <>
                        <h1
                            data-testid="hero_headline"
                            className={`hero-banner${baseClass}__headline`}
                        >
                            {translatableText.heroBannerHeadline.text}
                        </h1>
                        <p
                            data-testid="hero_subheadline"
                            className={`hero-banner${baseClass}__headline--secondary`}
                        >
                            {subHeader}
                        </p>
                    </>
                )}

                {searchField}
            </div>
        </section>
    );
};
