import React, { useEffect, useState } from 'react';
import SearchIcon from '@atlaskit/icon/glyph/search';
import './search.less';
import analytics from '../../utils/analytics/analytics';
import { Deployment } from '../../pages/search-results/types';

interface SearchFiltersProps {
    productNameId?: string;
    productDeployment?: string;
    productVersion?: string;
}

interface SearchProps {
    onSearch: (searchValue: string, searchFilters?: SearchFiltersProps) => void;
    className?: string;
    placeholder?: string;
    initialValue?: string;
    analyticsMetadata: {
        parentComponent: string;
        [key: string]: unknown;
    };
    searchFilters?: SearchFiltersProps;
    onChange?: (searchValue: string) => void;
}

interface TrackEventProps {
    action: string;
    component: string;
    metadata?: Record<string, unknown>;
}

const anonymizeEmailInSearchTerm = (text: string) => {
    // eslint-disable-next-line unicorn/better-regex
    const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;
    return text.replace(emailRegex, '***@***.***');
};

export const handleOnSearch = async (
    searchValue: string,
    searchFilters?: SearchFiltersProps
) => {
    const {
        productNameId = '',
        productDeployment = '',
        productVersion = ''
    } = searchFilters || {};
    const filterQueryParams = new URLSearchParams();

    if (productNameId && productDeployment) {
        const deployment =
            productDeployment === 'Cloud'
                ? Deployment.CLOUD
                : Deployment.DATA_CENTER;
        filterQueryParams.set('deployment', deployment);
        filterQueryParams.set('product', productNameId);

        if (deployment === Deployment.DATA_CENTER) {
            filterQueryParams.set('version', productVersion);
        }
    }

    const filterQueryParamsString = filterQueryParams.toString()
        ? `&${filterQueryParams.toString()}`
        : '';

    const encodedUrl = `/search-results/?searchTerm=${encodeURIComponent(
        searchValue
    )}${filterQueryParamsString}`;

    window.open(encodedUrl, '_blank');

    trackAnalyticsEvent({
        action: 'redirect',
        component: 'search',
        metadata: {
            searchValue: anonymizeEmailInSearchTerm(searchValue),
            redirectTo: 'SAC search',
            redirectToUrl: encodedUrl
        }
    });
};

const trackAnalyticsEvent = ({
    action,
    component,
    metadata
}: TrackEventProps) => {
    analytics.trackEvent('search', {
        // @ts-ignore
        event: action,
        eventComponent: component,
        actionSubjectId: 'searchField',
        category: 'search',
        action,
        label: 'search field',
        ...metadata
    });
};

export const Search = ({
    onSearch,
    placeholder = '',
    initialValue = '',
    className,
    analyticsMetadata,
    searchFilters = {},
    onChange
}: SearchProps) => {
    const [value, setValue] = useState<string>('');
    const [initialSearchTriggered, setInitialSearchTriggered] =
        useState<boolean>(false);

    useEffect(() => {
        if (initialValue && !initialSearchTriggered) {
            setValue(initialValue);
            onSearch(initialValue);
            setInitialSearchTriggered(true);
        }
    }, [initialValue, initialSearchTriggered, onSearch]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setValue(value);
        if (onChange) {
            onChange(value);
        }
    };

    const handleKeyUp = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            trackAnalyticsEvent({
                action: 'entered',
                component: 'searchInput',
                metadata: {
                    ...analyticsMetadata,
                    ...searchFilters,
                    searchValue: anonymizeEmailInSearchTerm(value)
                }
            });
            onSearch(value, searchFilters);
        }
    };

    const handleOnClick = () => {
        trackAnalyticsEvent({
            action: 'clicked',
            component: 'searchIcon',
            metadata: {
                ...analyticsMetadata,
                ...searchFilters,
                searchValue: anonymizeEmailInSearchTerm(value)
            }
        });
        onSearch(value, searchFilters);
    };

    const handleOnFocus = () => {
        trackAnalyticsEvent({
            action: 'focused',
            component: 'searchInput',
            metadata: {
                ...analyticsMetadata,
                ...searchFilters
            }
        });
    };

    const classes = [`search`];
    if (className) {
        classes.push(className);
    }

    return (
        <div className={classes.join(' ')} data-testid="search-div">
            <input
                data-testid="search-input"
                aria-label="Search site content"
                className={`search__input`}
                type="search"
                name="query"
                placeholder={placeholder}
                value={value}
                onChange={handleChange}
                onKeyUp={handleKeyUp}
                onFocus={handleOnFocus}
            />
            <div
                data-testid="search-icon"
                className={`search__icon`}
                role="button"
                aria-label="Search site content"
                tabIndex={0}
                onClick={handleOnClick}
                onKeyUp={handleKeyUp}
            >
                <SearchIcon label="Search" />
            </div>
        </div>
    );
};
