import React, { useState } from 'react';
import Checkbox from '@atlaskit/checkbox';
import Button from '@atlaskit/button/new';
import ChevronUpIcon from '@atlaskit/icon/glyph/chevron-up';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import analytics from '../../utils/analytics';
import { SearchOptionsGenericValue } from '../../pages/search-results/types';

import './multi-select-list-filter.less';

export interface MultiSelectListFilterProps {
    title: string;
    selected: SearchOptionsGenericValue[];
    optionsLists: {
        header?: SearchOptionsGenericValue;
        options: SearchOptionsGenericValue[];
    }[];
    isScrollable?: boolean;
    onChange: (selected: SearchOptionsGenericValue[]) => void;
    analyticsMetadata?: object;
}

interface GetChevronIconProps {
    showFilter: boolean;
    setShowFilter: (showFilter: boolean) => void;
}

const getChevronIcon = ({ showFilter, setShowFilter }: GetChevronIconProps) => {
    return (
        <Button
            testId="multi-select-list-filter__chevron"
            appearance="subtle"
            iconAfter={(iconProps) =>
                showFilter ? (
                    <ChevronUpIcon {...iconProps} size="large" />
                ) : (
                    <ChevronDownIcon {...iconProps} size="large" />
                )
            }
            onClick={() => setShowFilter(!showFilter)}
        >
            <></>
        </Button>
    );
};

export const MultiSelectListFilter = ({
    title,
    optionsLists,
    selected,
    onChange,
    isScrollable = false,
    analyticsMetadata = {}
}: MultiSelectListFilterProps) => {
    const [showFilter, setShowFilter] = useState<boolean>(true);

    const isOptionSelected = (option: SearchOptionsGenericValue) => {
        return selected.some((item) => item.key === option.key);
    };

    const handleChange = (option: SearchOptionsGenericValue) => {
        const isSelected = isOptionSelected(option);
        let newSelected: SearchOptionsGenericValue[];

        if (isSelected) {
            // Remove the option if it's already selected
            newSelected = selected.filter((item) => item.key !== option.key);
        } else {
            // Add the option if it's not selected
            newSelected = [...selected, { ...option }];
        }

        onChange(newSelected);
        analytics.trackEvent('multi select list filter', {
            // @ts-ignore
            event: isSelected ? 'deselected' : 'selected',
            eventComponent: 'checkbox',
            actionSubjectId: 'multiSelectListFilter',
            category: 'filter',
            action: isSelected ? 'deselected' : 'selected',
            label: 'multi select list filter',
            filterTitle: title,
            selectedFilter: option.key,
            filterList: optionsLists.flatMap((list) =>
                list.options.map((opt) => opt.key)
            ),
            ...analyticsMetadata
        });
    };

    return (
        <div
            className="multi-select-list-filter"
            data-testid="multi-select-list-filter__container"
        >
            <div
                className="multi-select-list-filter__heading"
                data-testid="multi-select-list-filter__heading"
            >
                <span
                    className="multi-select-list-filter__title"
                    data-testid="multi-select-list-filter__title"
                >
                    {title.toUpperCase()}
                </span>
                {getChevronIcon({ showFilter, setShowFilter })}
            </div>
            {showFilter && (
                <div
                    data-testid="multi-select-list-filter__options-list__container"
                    className={`multi-select-list-filter__options-list__container ${
                        isScrollable
                            ? 'multi-select-list-filter__options-list__container__scrollable'
                            : ''
                    }`}
                >
                    {optionsLists.map((optionsList) => (
                        <div
                            key={optionsList?.header?.key ?? 'default'}
                            className="multi-select-list-filter__options-list"
                            data-testid={`multi-select-list-filter__options-list-${
                                optionsList?.header?.key ?? 'default'
                            }`}
                        >
                            {optionsList?.header?.name && (
                                <div
                                    className="multi-select-list-filter__header"
                                    data-testid={`multi-select-list-filter__header-${optionsList.header.key}`}
                                >
                                    {optionsList.header.name}
                                </div>
                            )}
                            {optionsList.options.map((option) => (
                                <div
                                    key={option.key}
                                    className="multi-select-list-filter__checkbox"
                                    data-testid={`multi-select-list-filter__checkbox-container-${option.key}`}
                                    data-value={isOptionSelected(option)}
                                >
                                    <Checkbox
                                        key={option.key}
                                        name={option.key}
                                        value={option.key}
                                        label={option.name}
                                        testId={`multi-select-list-filter__checkbox-${option.key}`}
                                        isChecked={isOptionSelected(option)}
                                        onChange={() => handleChange(option)}
                                    />
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
