import React, { Component, ReactNode } from 'react';
import SearchIcon from '@atlaskit/icon/glyph/search';
import MoreIcon from '@atlaskit/icon/glyph/more';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import ChevronLeftLargeIcon from '@atlaskit/icon/glyph/chevron-left-large';
import ChevronUpIcon from '@atlaskit/icon/glyph/chevron-up';
import AppSwitcherIcon from '@atlaskit/icon/glyph/app-switcher';
import camelCase from 'lodash/camelCase';
import debounce from 'lodash/debounce';
import Dropdown from '../dropdown';
import {
    TABLET_BREAKPOINT,
    DESKTOP_BREAKPOINT
} from '../../../common/constants';
import { ContactListItem } from '../contact';
// @ts-ignore
import AccountMenu from '../account-menu';
import { track } from '../atlas-link';
// @ts-ignore
import PreviewBanner from '../preview-banner';
import './header.less';
import Bannerman from '../bannerman';
import { translatableText } from '../../utils/translatable-text';
import { GuidesDropdownButton } from './guides-dropdown-button';
import analytics from '../../utils/analytics/analytics';
import AtlasLink from '../atlas-link/atlas-link';
import { GuideType } from '../../pages/collection/guide.type';

import {
    DOCUMENTATION,
    DOCUMENTATION_DROPDOWN,
    GET_STARTED,
    GET_STARTED_DROPDOWN,
    PRODUCTS,
    PRODUCTS_DROPDOWN,
    RESOURCES,
    RESOURCES_DROPDOWN,
    WAFFLE_MENU,
    keyCodes
} from './constants';
import { WAC_URL } from '../../utils/constants';
import { AdditionalLinkType } from '.';
import { CollectionReference } from '../../../content/types/content/collection';
import { Product } from '../product/product.type';
import { AppAndThemeCards } from '../dropdown/side-nav';
import { CardResource } from '../card/card.type';
import { Deployment } from '../../pages/search-results/types';
import SkipLink from '../skip-link/skip-link';
import { Menu } from '../../../content/types/content';

const getActionSubjectIdFromDropdownType = (type: string) => {
    switch (type) {
        case DOCUMENTATION:
            return DOCUMENTATION_DROPDOWN;
        case GET_STARTED:
            return GET_STARTED_DROPDOWN;
        case PRODUCTS:
            return PRODUCTS_DROPDOWN;
        case RESOURCES:
            return RESOURCES_DROPDOWN;
        default:
            return '';
    }
};

export interface Resources {
    id: string;
    cards: CardResource[];
    type: string;
}

export interface HeaderProps {
    resources: Resources | undefined;
    hideContactButton?: boolean;
    // Title overrides the Atlassian Logo in the header if provided
    title?: string | null;
    showProductDropdownAsWaffle?: boolean;
    primaryListItems?: ReactNode;
    secondaryListItems?: ReactNode;
    additionalLinks: AdditionalLinkType[];
    showPartnerRequestItems?: boolean;
    logo: string;
    id: string;
    showPreviewBanner: boolean;
    pageId: string;
    supportHomePath?: string;
    supportFormUrls?: {
        authenticated: string | null;
        unauthenticated: string | null;
    };
    shouldRenderDocumentation?: boolean;
    collections?: CollectionReference[];
    atlassianLogo: { url: string };
    atlassianSupportLogo: { url: string };
    atlassianSupportLogoV2?: { url: string };
    atlassianSupportLogoV3?: { url: string };
    serverProducts: Product[];
    cloudProducts: Product[];
    relatedAppReferences?: AppAndThemeCards[] | undefined;
    productIcons?: {
        [key in Deployment]: string;
    };
    isNavRedesignEnabled?: boolean;
    menus?: Menu[];
    renderV2?: boolean;
}

interface HeaderState {
    dropdown: {
        isOpen: boolean;
        type: string;
        mobile?: boolean | null;
    };
    renderV2?: boolean;
}

// TODO: Convert this to a functional component
export default class Header extends Component<HeaderProps, HeaderState> {
    headerRef = React.createRef<HTMLDivElement>();
    triggerElement: HTMLElement | null = null;

    constructor(props: HeaderProps) {
        super(props);
        this.state = {
            dropdown: {
                isOpen: false,
                type: '',
                mobile: null
            }
        };
    }

    componentDidMount() {
        window.addEventListener(
            'resize',
            debounce(this.updateViewport.bind(this), 100)
        );
        window.addEventListener('mousedown', this.onOutsideClick);
        window.addEventListener('keydown', this.onKeyDown);
        this.updateViewport();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateViewport);
        window.removeEventListener('mousedown', this.onOutsideClick);
        window.removeEventListener('keydown', this.onKeyDown);
    }

    onKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            const { isOpen } = this.state.dropdown;
            if (isOpen) {
                this.handleDropdownEvent({ shouldClose: true });
            }
        }
    };

    search() {
        track('/search-results/', 'Search', 'search button');
        window.open('/search-results/', '_blank');
    }

    setStateOpenMobileDropdown = () => {
        this.setState({
            dropdown: {
                type: '',
                isOpen: true,
                mobile: true
            }
        });
        analytics.trackEvent('mobileDropdown', {
            // @ts-ignore
            event: 'clicked',
            eventComponent: 'menu',
            actionSubjectId: 'mobileDropdown',
            category: 'header dropdown',
            action: 'click',
            label: 'mobile dropdown'
        });
    };

    updateViewport() {
        const { isOpen, type } = this.state.dropdown;
        if (
            window.innerWidth < TABLET_BREAKPOINT &&
            !this.state.dropdown.mobile
        ) {
            this.setState({
                dropdown: {
                    isOpen,
                    type,
                    mobile: true
                }
            });
        } else if (
            window.innerWidth >= TABLET_BREAKPOINT &&
            this.state.dropdown.mobile
        ) {
            this.setState({
                dropdown: {
                    isOpen,
                    type,
                    mobile: false
                }
            });
        }
    }

    handleDropdownToggle = (
        type: string,
        event: React.MouseEvent | React.KeyboardEvent,
        actionSubjectId: string
    ) => {
        this.triggerElement = event.currentTarget as HTMLElement;
        this.handleDropdownEvent({ type, actionSubjectId });
    };

    handleKeyDown = (
        e: React.KeyboardEvent,
        type: string,
        actionSubjectId: string
    ) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.handleDropdownToggle(type, e, actionSubjectId);
        }
    };

    handleDropdownEvent({
        shouldClose = false,
        actionSubjectId = '',
        type = ''
    } = {}) {
        this.setState(
            (prevState) => {
                const { isOpen: wasOpen, type: previousType } =
                    prevState.dropdown;

                // Need a force-closing functionality.
                if (shouldClose) {
                    return {
                        dropdown: { isOpen: false, type: '' }
                    };
                }

                // Not providing a type means dropdown should be toggled open or closed.
                if (!type) {
                    return {
                        dropdown: {
                            isOpen: !wasOpen,
                            type: ''
                        }
                    };
                }

                // Logic for dropdowns with types that receive clicks.
                const willBeOpened = type !== previousType;
                const newType = willBeOpened ? type : '';
                return {
                    dropdown: {
                        isOpen: willBeOpened,
                        type: newType
                    }
                };
            },
            () => {
                // Callback after state update
                if (shouldClose && this.triggerElement) {
                    this.triggerElement.focus();
                }
                actionSubjectId =
                    actionSubjectId || getActionSubjectIdFromDropdownType(type);
                this.trackDropdownAnalytics(type, actionSubjectId);
            }
        );
    }

    onOutsideClick = (e: MouseEvent) => {
        // Handles logic for clicking outside of the dropdown.
        const { current: header } = this.headerRef;
        const { target: clickedElement } = e;

        const headerContainsElement = header
            ? header.contains(clickedElement as Node)
            : false;

        if (headerContainsElement) {
            return;
        }

        const { isOpen: dropdownOpen } = this.state.dropdown;

        if (dropdownOpen && !headerContainsElement) {
            this.handleDropdownEvent({ shouldClose: true });
        }
    };

    // type change handler will only be called on a mobile screen size
    onTypeChangeHandler(type: string) {
        const { isOpen } = this.state.dropdown;
        this.setState({
            dropdown: {
                isOpen,
                type,
                mobile: true
            }
        });
    }

    getDropdownAriaLabel() {
        const { isOpen } = this.state.dropdown;

        return isOpen ? `Close dropdown` : `Open dropdown`;
    }

    trackDropdownAnalytics(type: string, actionSubjectId: string) {
        const shouldToggleDropdown = !type || this.state.dropdown.type === type;
        const isOpen = shouldToggleDropdown;
        // type can be an empty string, so conditionally
        // generate the event name
        const dropdownType = type.length > 0 ? type.toLowerCase() + ' ' : type;
        analytics.trackEvent(camelCase(dropdownType + 'dropdown'), {
            // @ts-ignore
            event: isOpen ? 'opened' : 'closed',
            eventComponent: 'menu',
            actionSubjectId: actionSubjectId,
            category: 'header dropdown',
            action: 'click',
            label: type
        });
    }

    // eslint-disable-next-line complexity
    renderHeaderControls() {
        const { type, mobile } = this.state.dropdown;
        const {
            title,
            atlassianSupportLogo,
            atlassianSupportLogoV2,
            atlassianSupportLogoV3,
            supportHomePath,
            isNavRedesignEnabled,
            renderV2
        } = this.props;

        const headerUrl = isNavRedesignEnabled
            ? atlassianSupportLogoV3?.url ||
              atlassianSupportLogoV2?.url ||
              atlassianSupportLogo?.url
            : atlassianSupportLogoV2?.url || atlassianSupportLogo?.url;

        const v2ClassSuffix = renderV2 ? '-v2' : '';

        return (
            <>
                {mobile && type ? (
                    <div className="header__mobile-nav">
                        <div
                            role="button"
                            tabIndex={0}
                            aria-label="Open dropdown"
                            onClick={this.setStateOpenMobileDropdown}
                            onKeyDown={(e) => {
                                e.keyCode === keyCodes.RETURN &&
                                    this.setStateOpenMobileDropdown();
                            }}
                        >
                            <ChevronLeftLargeIcon label="mobile" />
                        </div>
                        <h2>{type}</h2>
                    </div>
                ) : (
                    <>
                        <AtlasLink
                            href={
                                isNavRedesignEnabled
                                    ? WAC_URL
                                    : supportHomePath || '/'
                            }
                            className={`header${v2ClassSuffix}__nav-title`}
                            dataName={
                                supportHomePath
                                    ? 'supportHeaderButton'
                                    : 'supportHomeButton'
                            }
                        >
                            {title ? (
                                <h2 data-testid="header-support-link">
                                    {title}
                                </h2>
                            ) : (
                                <div
                                    className={
                                        isNavRedesignEnabled
                                            ? 'logo-container-redesign'
                                            : 'logo-container'
                                    }
                                >
                                    <img
                                        src={headerUrl}
                                        alt="Atlassian Logo"
                                        data-testid="atlassian-logo-nav"
                                    />
                                    {isNavRedesignEnabled && (
                                        <>
                                            <div className="divider" />
                                            <AtlasLink
                                                href={supportHomePath || '/'}
                                                data-testid="support-header-link"
                                            >
                                                <h1>Support</h1>
                                            </AtlasLink>
                                        </>
                                    )}
                                </div>
                            )}
                        </AtlasLink>
                    </>
                )}
            </>
        );
    }

    renderDropdown() {
        const { isOpen, type } = this.state.dropdown;
        const {
            cloudProducts,
            serverProducts,
            resources,
            additionalLinks,
            id,
            relatedAppReferences,
            supportFormUrls,
            collections,
            showProductDropdownAsWaffle,
            menus,
            renderV2
        } = this.props;

        const guides = collections?.filter(
            (guide) => guide.pageType === 'Guide'
        );

        return (
            <>
                <Dropdown
                    closeDropdown={() => {
                        this.handleDropdownEvent({ shouldClose: true });
                    }}
                    onChange={this.onTypeChangeHandler.bind(this)}
                    isOpen={isOpen}
                    id={id}
                    relatedAppReferences={relatedAppReferences}
                    type={type}
                    additionalLinks={additionalLinks}
                    resources={resources}
                    serverProducts={serverProducts}
                    cloudProducts={cloudProducts}
                    guides={guides}
                    supportFormUrls={supportFormUrls}
                    collections={collections}
                    showProductDropdownAsWaffle={showProductDropdownAsWaffle}
                    menus={menus}
                    renderV2={renderV2}
                />
            </>
        );
    }

    // eslint-disable-next-line complexity
    renderProductsDropdown() {
        const {
            cloudProducts,
            serverProducts,
            showProductDropdownAsWaffle,
            renderV2
        } = this.props;
        const {
            dropdown: { isOpen, type }
        } = this.state;

        if (!cloudProducts || !serverProducts) {
            return null;
        }

        const v2ClassSuffix = renderV2 ? '-v2' : '';
        const isProductsOpen = isOpen && type === PRODUCTS;

        return (
            <li
                style={
                    showProductDropdownAsWaffle
                        ? { visibility: 'hidden', width: 0 }
                        : { display: 'inherit' }
                }
            >
                <div
                    data-testid="products-dropdown-button"
                    aria-expanded={isProductsOpen}
                    className={`header${v2ClassSuffix}__nav-item`}
                    role="button"
                    tabIndex={0}
                    aria-label="products"
                    onClick={(e) => this.handleDropdownToggle(PRODUCTS, e, '')}
                    onKeyDown={(e) => this.handleKeyDown(e, PRODUCTS, '')}
                >
                    <span data-testid="products-dropdown">{`${translatableText.products.text} `}</span>
                    {renderV2 ? (
                        isProductsOpen ? (
                            <ChevronUpIcon label="products" size="large" />
                        ) : (
                            <ChevronDownIcon label="products" size="large" />
                        )
                    ) : (
                        <ChevronDownIcon label="products" />
                    )}
                </div>
                {isProductsOpen &&
                    window.innerWidth >= DESKTOP_BREAKPOINT &&
                    this.renderDropdown()}
            </li>
        );
    }

    renderResourcesDropdown() {
        const { resources } = this.props;
        const {
            dropdown: { isOpen, type }
        } = this.state;

        if (!resources) {
            return null;
        }

        const isResourcesOpen = isOpen && type === RESOURCES;

        return (
            <li>
                <div
                    aria-expanded={isResourcesOpen}
                    className={`header__nav-item`}
                    role="button"
                    tabIndex={0}
                    aria-label="resources"
                    onClick={(e) => this.handleDropdownToggle(RESOURCES, e, '')}
                    onKeyDown={(e) => this.handleKeyDown(e, RESOURCES, '')}
                >
                    <span data-testid="resources-dropdown">
                        {translatableText.resources.text}
                    </span>
                    <ChevronDownIcon label="Resources Dropdown" />
                </div>
                {isResourcesOpen &&
                    window.innerWidth >= DESKTOP_BREAKPOINT &&
                    this.renderDropdown()}
            </li>
        );
    }

    renderDocumentationDropdown() {
        const { shouldRenderDocumentation } = this.props;
        const {
            dropdown: { isOpen, type }
        } = this.state;

        if (!shouldRenderDocumentation) {
            return null;
        }

        const isDocumentationOpen = isOpen && type === DOCUMENTATION;

        return (
            <li>
                <div
                    className="header__nav-item"
                    role="button"
                    tabIndex={0}
                    aria-label="documentation"
                    onClick={(e) =>
                        this.handleDropdownToggle(DOCUMENTATION, e, '')
                    }
                    onKeyDown={(e) => this.handleKeyDown(e, DOCUMENTATION, '')}
                    data-testid="documentation-dropdown"
                >
                    <span>{translatableText.documentation.text}</span>
                    <ChevronDownIcon label="Documentation Dropdown" />
                </div>
                {isDocumentationOpen &&
                    window.innerWidth >= DESKTOP_BREAKPOINT &&
                    this.renderDropdown()}
            </li>
        );
    }

    renderContactButton() {
        const { hideContactButton, supportFormUrls, renderV2 } = this.props;
        if (hideContactButton) {
            return null;
        }
        const v2ClassSuffix = renderV2 ? '-v2' : '';

        return (
            <li>
                <div className={`header${v2ClassSuffix}__nav-item contact-btn`}>
                    <ContactListItem supportFormUrls={supportFormUrls} />
                </div>
            </li>
        );
    }

    renderWaffleMenu() {
        const { mobile } = this.state.dropdown;
        const { showProductDropdownAsWaffle } = this.props;

        const {
            dropdown: { isOpen, type }
        } = this.state;

        if (!showProductDropdownAsWaffle || mobile) {
            return null;
        }

        const isWaffleOpen = isOpen && type === PRODUCTS;
        return (
            <>
                <div
                    data-testid="waffle_menu"
                    className="header__nav-item header__nav-item--waffle"
                    onClick={(e) =>
                        this.handleDropdownToggle(PRODUCTS, e, WAFFLE_MENU)
                    }
                    role="button"
                    tabIndex={0}
                    aria-label="Open products dropdown"
                    onKeyDown={(e) =>
                        this.handleKeyDown(e, PRODUCTS, WAFFLE_MENU)
                    }
                >
                    <AppSwitcherIcon label="Products Dropdown" />
                </div>
                {isWaffleOpen &&
                    window.innerWidth >= DESKTOP_BREAKPOINT &&
                    this.renderDropdown()}
            </>
        );
    }

    renderMenus() {
        const { menus } = this.props;

        if (!menus?.length) {
            return null;
        }

        const {
            dropdown: { isOpen, type }
        } = this.state;

        return menus.map((menu) => {
            const { heading, id } = menu;
            const isMenuOpen = isOpen && type === heading;

            return (
                <li key={id}>
                    <div
                        data-testid="menu-nav-item"
                        aria-expanded={isMenuOpen}
                        className="header-v2__nav-item"
                        role="button"
                        tabIndex={0}
                        aria-label={heading}
                        onClick={(e) =>
                            this.handleDropdownToggle(heading, e, '')
                        }
                        onKeyDown={(e) => this.handleKeyDown(e, heading, '')}
                    >
                        <span>{heading}</span>
                        {isMenuOpen ? (
                            <ChevronUpIcon label="" size="large" />
                        ) : (
                            <ChevronDownIcon label="" size="large" />
                        )}
                    </div>
                    {isMenuOpen &&
                        window.innerWidth >= DESKTOP_BREAKPOINT &&
                        this.renderDropdown()}
                </li>
            );
        });
    }

    renderGuidesDropdown() {
        const { collections } = this.props;
        const {
            dropdown: { isOpen, type }
        } = this.state;
        const guides = collections?.filter(
            (guide) => guide.pageType === 'Guide'
        );
        if (!guides || guides.length === 0) {
            return null;
        }
        const isGuidesOpen = isOpen && type === GET_STARTED;
        return (
            <>
                <GuidesDropdownButton
                    guides={guides as GuideType[]}
                    onClick={(e) =>
                        this.handleDropdownToggle(GET_STARTED, e, '')
                    }
                />
                {isGuidesOpen &&
                    window.innerWidth >= DESKTOP_BREAKPOINT &&
                    this.renderDropdown()}
            </>
        );
    }

    // eslint-disable-next-line complexity
    render() {
        const { isOpen } = this.state.dropdown;
        const {
            primaryListItems,
            secondaryListItems,
            showPartnerRequestItems,
            showPreviewBanner,
            renderV2
        } = this.props;

        const v2ClassSuffix = renderV2 ? '-v2' : '';

        return (
            <div
                className="header-components"
                data-testid="global_header"
                data-event-container="globalHeader"
                ref={this.headerRef}
            >
                <SkipLink />
                <Bannerman />
                {showPreviewBanner && <PreviewBanner />}
                <header
                    className={
                        showPreviewBanner
                            ? `subheader header${v2ClassSuffix}`
                            : `header${v2ClassSuffix}`
                    }
                >
                    <div className={`header${v2ClassSuffix}__inner-container`}>
                        <div
                            className={`header${v2ClassSuffix}__inner-content`}
                        >
                            <div
                                className={`header${v2ClassSuffix}__nav`}
                                data-testid="page-header"
                                data-event-container="pageHeader"
                            >
                                <div
                                    className={`header${v2ClassSuffix}__nav--logo`}
                                >
                                    {this.renderWaffleMenu()}
                                    {this.renderHeaderControls()}
                                </div>
                                <div
                                    className={`header${v2ClassSuffix}__nav--primary`}
                                >
                                    <ul
                                        className={`header${v2ClassSuffix}__nav-list header${v2ClassSuffix}__products-resources`}
                                    >
                                        {this.renderProductsDropdown()}
                                        {this.renderGuidesDropdown()}
                                        {primaryListItems}
                                        {this.renderDocumentationDropdown()}
                                        {renderV2
                                            ? this.renderMenus()
                                            : this.renderResourcesDropdown()}
                                    </ul>
                                </div>
                            </div>
                            <div
                                className={`header${v2ClassSuffix}__nav--secondary`}
                            >
                                <ul
                                    className={`header${v2ClassSuffix}__nav-list`}
                                >
                                    {secondaryListItems}
                                    {this.renderContactButton()}
                                    <li>
                                        <div
                                            data-testid="search_button"
                                            className={`header${v2ClassSuffix}__nav-item header${v2ClassSuffix}__nav-item--icon header${v2ClassSuffix}__nav-item--search-icon`}
                                            role="button"
                                            tabIndex={0}
                                            aria-label="Open search page"
                                            onClick={this.search}
                                            onKeyDown={(e) => {
                                                e.keyCode === keyCodes.RETURN &&
                                                    this.search();
                                            }}
                                        >
                                            <SearchIcon label="Search Page" />
                                        </div>
                                    </li>
                                    <li>
                                        <div className="header-v2__divider" />
                                    </li>
                                    <li>
                                        <div
                                            className={`header${v2ClassSuffix}__nav-item account-btn-wrapper`}
                                        >
                                            <AccountMenu
                                                showPartnerRequestItems={
                                                    showPartnerRequestItems
                                                }
                                            />
                                        </div>
                                    </li>
                                    <li>
                                        <div
                                            className={`header${v2ClassSuffix}__nav-item header${v2ClassSuffix}__nav-item--icon header${v2ClassSuffix}__nav-item--dropdown-button`}
                                            role="button"
                                            tabIndex={0}
                                            aria-label={this.getDropdownAriaLabel()}
                                            onClick={(e) =>
                                                this.handleDropdownToggle(
                                                    '',
                                                    e,
                                                    ''
                                                )
                                            }
                                            onKeyDown={(e) =>
                                                this.handleKeyDown(e, '', '')
                                            }
                                        >
                                            {isOpen ? (
                                                <CrossIcon label="Dropdown Open" />
                                            ) : (
                                                <MoreIcon label="Dropdown Not Open" />
                                            )}
                                        </div>
                                        {isOpen &&
                                            window.innerWidth >=
                                                TABLET_BREAKPOINT &&
                                            window.innerWidth <
                                                DESKTOP_BREAKPOINT &&
                                            this.renderDropdown()}
                                    </li>
                                </ul>
                            </div>
                            <div
                                className={`header${v2ClassSuffix}__nav--mobile`}
                            >
                                <ul
                                    className={`header${v2ClassSuffix}__nav-list`}
                                >
                                    <li>
                                        <div
                                            className={`header${v2ClassSuffix}__nav-item header${v2ClassSuffix}__nav-item--icon header${v2ClassSuffix}__nav-item--search-icon`}
                                            role="button"
                                            tabIndex={0}
                                            aria-label="Open search page"
                                            onClick={this.search}
                                            onKeyDown={(e) => {
                                                e.keyCode === keyCodes.RETURN &&
                                                    this.search();
                                            }}
                                        >
                                            <SearchIcon label="Open search page" />
                                        </div>
                                    </li>
                                    <li>
                                        <div
                                            className={`header${v2ClassSuffix}__nav-item account-btn-wrapper`}
                                        >
                                            <AccountMenu
                                                showPartnerRequestItems={
                                                    showPartnerRequestItems
                                                }
                                                isMobile={true}
                                            />
                                        </div>
                                    </li>
                                    <li>
                                        <div
                                            className={`header${v2ClassSuffix}__nav-item header${v2ClassSuffix}__nav-item--icon`}
                                            role="button"
                                            tabIndex={0}
                                            aria-label={
                                                isOpen
                                                    ? 'Close dropdown'
                                                    : 'Open dropdown'
                                            }
                                            onClick={(e) =>
                                                this.handleDropdownToggle(
                                                    '',
                                                    e,
                                                    ''
                                                )
                                            }
                                            onKeyDown={(e) =>
                                                this.handleKeyDown(e, '', '')
                                            }
                                        >
                                            {isOpen ? (
                                                <CrossIcon label="Close Dropdown" />
                                            ) : (
                                                <MoreIcon label="Open Dropdown" />
                                            )}
                                        </div>
                                        {isOpen &&
                                            window.innerWidth <
                                                TABLET_BREAKPOINT &&
                                            this.renderDropdown()}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </header>
            </div>
        );
    }
}
