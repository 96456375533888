import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { ReactRenderer } from '@atlaskit/renderer';
// @ts-ignore
import { parse, stringify } from 'himalaya';
import { JSONUtils } from '../../../content/utils/json-utils';
import Logger from '../../../logger';
import { IntlProvider } from 'react-intl-next';

// Helper function to recursively search for the first <p> tag's children
const findParagraphChildren = (nodes: any[]): any[] => {
    for (const node of nodes) {
        if (node.tagName === 'p') {
            return node.children;
        }
        if (node.children) {
            const result = findParagraphChildren(node.children);
            if (result.length > 0) {
                return result;
            }
        }
    }
    return [];
};

// parse html and retrieve inline content from inside divs created by '@atlaskit/renderer'
const renderInline = (body: JSX.Element) => {
    try {
        const html = renderToStaticMarkup(body);
        const parsedHTML = parse(html);

        const content = findParagraphChildren(parsedHTML);
        const inlineHTML = stringify(content);

        return (
            <span className="text-fragment" data-testid="inline-text-fragment">
                <span dangerouslySetInnerHTML={{ __html: inlineHTML }} />
            </span>
        );
    } catch (error) {
        Logger.error({ error }, 'Error while rendering inline text fragment');
        throw error;
    }
};

interface TextFragmentExtensionProps {
    adf: any;
    isInline: boolean;
    extensionHandlers: any;
}

export const TextFragmentExtension = ({
    adf,
    extensionHandlers,
    isInline
}: TextFragmentExtensionProps) => {
    const body = (
        <IntlProvider locale="en">
            <ReactRenderer
                // @ts-ignore
                document={JSONUtils.parseBodyField(adf)}
                extensionHandlers={extensionHandlers}
            />
        </IntlProvider>
    );

    return isInline ? (
        renderInline(body)
    ) : (
        <div className="text-fragment" data-testid="text-fragment">
            {body}
        </div>
    );
};
