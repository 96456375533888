import React from 'react';
import ComponentCallout from '../../subcomponents/component-callout';
import ComponentCodeBlock from '../../subcomponents/component-code-block';
import ComponentKbImage from '../../subcomponents/component-kb-image';
import type { GqlKbArticleRichTextBlockEntry } from '../../../../../content/types/content/kb-article';

const renderBlockEntry = (
    entry: GqlKbArticleRichTextBlockEntry
): JSX.Element => {
    const type = entry.__typename;
    switch (type) {
        case 'ComponentCallout':
            return <ComponentCallout entry={entry} />;
        case 'ComponentCodeBlock':
            return <ComponentCodeBlock entry={entry} />;
        case 'ComponentKbImage':
            return <ComponentKbImage entry={entry} />;
        default: {
            // This will raise a TS error if a new type is added without including a case to handle it.
            const unknownType: never = type;
            throw new Error(
                `Rendering the entry type '${unknownType}' in block format is not supported.`
            );
        }
    }
};

export default renderBlockEntry;
