export enum Deployment {
    CLOUD = 'cloud',
    DATA_CENTER = 'data_center'
}

export enum SortBy {
    RELEVANCE = 'relevance',
    LAST_PUBLISHED = 'last_published'
}

export interface SearchOptionsGenericValue {
    key: string;
    name: string;
}

export interface SearchOptionsProductValue extends SearchOptionsGenericValue {
    versions?: string[];
}

export interface SearchOptionsPlatformValue extends SearchOptionsGenericValue {
    products: SearchOptionsProductValue[];
}

export interface SearchOptionsCategoryValue extends SearchOptionsGenericValue {
    contentTypes: SearchOptionsGenericValue[];
}

export interface SearchOptionsApiResponse {
    filters: {
        platforms: SearchOptionsPlatformValue[];
        contentTypes: SearchOptionsGenericValue[];
        categories: SearchOptionsCategoryValue[];
    };
    sortOptions: SearchOptionsGenericValue[];
}
