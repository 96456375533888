import React from 'react';

export const LinkExtension = ({
    href,
    label,
    shouldPreventRedirect
}: {
    href: string;
    label: string;
    shouldPreventRedirect: boolean;
}) => (
    <a
        href={href}
        data-testid="sac-link-extension"
        target="_blank"
        rel="noopener noreferrer"
        onClick={(e) => {
            if (shouldPreventRedirect) {
                e.preventDefault();
            }
        }}
    >
        {label}
    </a>
);
