import { string, shape } from 'prop-types';

export const SitewideMetadataType = shape({
    id: string,
    language: string,
    publisher: string,
    license: string,
    logo: string,
    brand: string,
    legalName: string,
    owns: string,
    siteName: string
});
