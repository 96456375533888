import { isString } from 'lodash';

export const JSONUtils = {
    parse: (json: string | null): object => {
        if (json && isString(json)) {
            return JSON.parse(json);
        }
        return {};
    },
    parseBodyField: (field: string | AdfDocument): AdfDocument => {
        if (isString(field)) {
            if (field === '') {
                return { content: [], type: '' };
            }
            return JSON.parse(field);
        }
        return field;
    }
};

export interface AdfDocument {
    content: AdfDocument[];
    type: string;
}
