import React, { useEffect, useState } from 'react';
import { Header, HeaderListItem } from '../../components/header';
import Breadcrumbs from '../../components/breadcrumbs/breadcrumbs';
import Footer from '../../components/footer';
import AuthorTools from '../../components/author-tools';
import KbArticleSidebar from '../../components/kb-article-sidebar';
import Sidebar from '../../components/sidebar';
import FeedbackButtons from '../../components/feedback-buttons';
import { translatableText } from '../../utils/translatable-text';
import PlatformNotice from '../../components/platform-notice';
import { getSupportTitle } from '../../../content/context-utils';
import { PAGE_KB_ARTICLE } from '../../../contentful/content-types';
import type {
    GqlKbArticlePage,
    KbArticlePage,
    KbPageReferences
} from '../../../content/types/content/kb-article';
import './kb-article.less';
import AdditionalHelp from '../../components/additional-help';
import KbArticleRtfContent from '../../components/kb-article-rtf-content';
import { useContentfulLiveUpdates } from '@contentful/live-preview/react';
import {
    kbArticleCommonTransformer,
    KbArticleCommonTransformations
} from '../../../content/loaders/kb-article/client-shared-utils/kb-article-common.transformer';

export type KbArticlePageEntry = KbArticlePage & {
    header: any; // TODO: See https://hello.jira.atlassian.cloud/browse/CSTE-2182 for more on header and resources typing
    preview: boolean;
    resources: any;
    metadata: any;
};

interface KbArticlePageProps {
    entry: KbArticlePageEntry;
}

const getReferencesFromArticle = (page: KbArticlePage): KbPageReferences => ({
    kbHomePageReference: {
        url: page.homePageReference.url,
        title: page.homePageReference.title
    },
    kbArticlePageReference: {
        url: page.url,
        title: page.title
    }
});

const convertTimestamp = (timestamp: string) => {
    const date = new Date(timestamp);

    if (Number.isNaN(date.getTime())) {
        return;
    }

    return `${date.toLocaleString('default', {
        month: 'long',
        timeZone: 'UTC'
    })} ${date.getUTCDate()}, ${date.getUTCFullYear()}`;
};

// eslint-disable-next-line complexity
const KbArticle = ({ entry }: KbArticlePageProps) => {
    const updatedEntry = useContentfulLiveUpdates(entry, {
        skip: !entry.preview
    });

    const {
        title,
        body,
        header,
        id,
        preview,
        resources,
        homePageReference,
        appVersions,
        productVersions,
        platform
    } = updatedEntry;

    // Set initial state for preview data
    const [previewData, setPreviewData] =
        useState<KbArticleCommonTransformations>({
            body,
            tableOfContents: [],
            platform,
            appVersions: [],
            productVersions: []
        });

    useEffect(() => {
        if (!preview) {
            return;
        }
        // Get the updated preview data
        setPreviewData(
            kbArticleCommonTransformer(
                updatedEntry as KbArticlePageEntry & GqlKbArticlePage
            )
        );
    }, [preview, updatedEntry]);

    const headerListItem = (
        <HeaderListItem
            label={translatableText.knowledgeBase.text}
            href={homePageReference.url}
        />
    );

    const lastPublished = entry.lastPublished
        ? convertTimestamp(entry.lastPublished)
        : undefined;

    const apps = appVersions.map((v) => v.app.appName);
    const products = productVersions.map((v) => v.product.productName);
    const platforms = [
        ...appVersions.map((v) => v.platform),
        ...productVersions.map((v) => v.platform)
    ];

    return (
        <>
            <AuthorTools pageId={id} tooltipContent="Edit this KB Article" />
            <div
                className="kb-article section-page flex-column-page"
                data-testid="page-template"
            >
                <div className="content">
                    <Header
                        title={getSupportTitle(homePageReference)}
                        id={header.id}
                        pageId={id}
                        cloudProducts={header.cloudProducts}
                        serverProducts={header.serverProducts}
                        additionalLinks={header.additionalLinks}
                        primaryListItems={headerListItem}
                        showProductDropdownAsWaffle={true}
                        showPreviewBanner={preview}
                        resources={resources}
                        logo={header.logo}
                        atlassianSupportLogo={header.atlassianSupportLogo}
                        atlassianLogo={header.atlassianLogo}
                        shouldRenderDocumentation={false}
                        supportFormUrls={{
                            authenticated: null,
                            unauthenticated: null
                        }}
                    />
                    <div className="main-content-container flex-container margin-top-medium">
                        <main className="kb-article__container">
                            <div className="kb-article__sub-header">
                                <Breadcrumbs
                                    entryId={id}
                                    pageTree={[]}
                                    type={PAGE_KB_ARTICLE}
                                    context={getReferencesFromArticle(entry)}
                                />
                            </div>
                            <article
                                className="kb-article__inner-container margin-top-medium"
                                id="maincontent"
                            >
                                <div className="title margin-bottom-medium">
                                    <h1>{title}</h1>
                                </div>
                                <div className="kb-article__body">
                                    <PlatformNotice
                                        platform={
                                            preview
                                                ? previewData.platform
                                                : platform
                                        }
                                    />
                                    <KbArticleRtfContent
                                        articleBody={
                                            preview ? previewData.body : body
                                        }
                                    />
                                    {lastPublished && (
                                        <div
                                            className="kb-article__last-published"
                                            data-testid="kb-article-last-published"
                                        >
                                            Updated on {lastPublished}
                                        </div>
                                    )}
                                    <FeedbackButtons
                                        productName={homePageReference.title}
                                        pageId={id}
                                    />
                                </div>
                                <Sidebar>
                                    <KbArticleSidebar
                                        page={
                                            preview
                                                ? ({
                                                      ...entry,
                                                      ...previewData
                                                  } satisfies KbArticlePageEntry)
                                                : entry
                                        }
                                    />
                                </Sidebar>
                            </article>
                            <AdditionalHelp
                                productNames={[...products, ...apps]}
                                deployments={[...platforms]}
                            />
                        </main>
                    </div>
                </div>
                <Footer
                    logo={header.atlassianLogo.url}
                    className="margin-top-large"
                />
            </div>
        </>
    );
};

export default KbArticle;
