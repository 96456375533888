import Icons from './icons';
import Logos from './logos';

export default async () => {
    const icons = await Icons();

    return {
        ...icons,
        ...Logos
    };
};
