import React, { FC, ReactNode } from 'react';
import Button from '@atlaskit/button';
import { AtlasLink } from '../atlas-link';
//@ts-ignore
import WithContentfulEditMode from '../with-contentful-edit';
import { IconDimensions } from './card.type';
import { SvgIcon } from './svg-icon';
import './card.less';

export interface CardProps {
    setRef: React.Ref<any>;
    className: string;
    key?: string;
    title: string;
    description: string;
    buttonLink: string;
    buttonText?: string;
    icon?: string;
    iconDimensions?: IconDimensions;
    // This is automatically passed in when using WithContentfulEditMode hoc
    contentfulEditIcon?: ReactNode;
    analytics?: string;
    type?: string;
    titleHeight?: number;
    measureRef?: any;
}

// eslint-disable-next-line complexity
const Card: FC<CardProps> = ({
    setRef,
    className,
    title,
    description,
    buttonLink,
    buttonText,
    icon,
    iconDimensions,
    contentfulEditIcon,
    analytics,
    type,
    titleHeight,
    measureRef
}: CardProps) => {
    let width = '';

    if (type === 'full') {
        width = 'cards__item--full-width';
    }

    if (type === 'third-width') {
        width = 'cards__item--third-width';
    }

    let IconComponent = null;
    if (icon) {
        IconComponent = <SvgIcon icon={icon} iconDimensions={iconDimensions} />;
    }

    return (
        <li
            data-testid="card_item"
            className={['cards__item', width, className].join(' ')}
        >
            <article>
                <AtlasLink
                    setRef={setRef}
                    href={buttonLink}
                    className={'cards__link'}
                    dataName={(analytics ? analytics : title) + ' card'}
                >
                    <>
                        {IconComponent}
                        <div className="cards__main">
                            <header>
                                <div
                                    className="cards__title-container"
                                    style={
                                        titleHeight
                                            ? {
                                                  height: `${titleHeight}px`
                                              }
                                            : {}
                                    }
                                >
                                    <p
                                        className="cards__title"
                                        ref={measureRef}
                                    >
                                        {title}
                                    </p>
                                </div>
                            </header>
                            {description && (
                                <p className="cards__body margin-top-small">
                                    {description}
                                </p>
                            )}
                            {buttonText && (
                                <span
                                    className={`cards__button-wrapper ${
                                        !description ? 'margin-top-medium' : ''
                                    }`}
                                    data-testid="card_button"
                                >
                                    <Button
                                        tabIndex={-1}
                                        className="cards__button"
                                    >
                                        {buttonText}
                                    </Button>
                                </span>
                            )}
                        </div>
                    </>
                </AtlasLink>
                {contentfulEditIcon ? contentfulEditIcon : null}
            </article>
        </li>
    );
};

const CardWithCEM = WithContentfulEditMode(Card);

export { Card, CardWithCEM };
