import slugify from 'slugify';

const slugifyTitle = (title: string) => {
    try {
        const slug = slugify(title, {
            replacement: '-',
            remove: /[^\w -]/g,
            lower: true
        });
        return slug;
    } catch {
        // eslint-disable-next-line no-console
        console.error(
            `There was a problem creating a slug for the entry: '${title}'`
        );
    }
};

export default slugifyTitle;
