import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import SectionMessage from '@atlaskit/section-message';
import './notice-hub.less';

const NoticeHub = ({ notice }: any) => {
    const { title, message, appearance: appearanceValue } = notice;
    const appearance =
        appearanceValue === 'Announcement'
            ? 'info'
            : appearanceValue.toLowerCase();

    return (
        <div className="notice-hub-container">
            <SectionMessage
                appearance={
                    // @ts-ignore
                    {
                        info: 'information',
                        confirmation: 'success',
                        change: 'discovery'
                    }[appearance] || appearance
                }
                testId={'notice-hub'}
            >
                <h2>{title}</h2>
                {documentToReactComponents(message.json)}
            </SectionMessage>
        </div>
    );
};

export default NoticeHub;
