import {
    DOCUMENTED_PRODUCT,
    CONTEXT,
    PRODUCT_LANDING_PAGE,
    CONTEXT_APP,
    CONTEXT_THEME,
    HOME_PAGE,
    NOTICE_HUB,
    PAGE_KB_HOME,
    PAGE_KB_ARTICLE
} from '../../contentful/content-types';
import { BasePathOptions, getBaseURLPath } from './get-base-url-path';
import { DocsHomeURLPathOptions, getDocsHomePath } from './get-docs-home-path';
import {
    DocumentationPageOptions,
    getDocumentationPagePath
} from './get-documentation-page-path';
import {
    getKbArticlePagePath,
    KbArticlePageOptions
} from './get-kb-article-path';
import { getKbHomePagePath, KbHomePageOptions } from './get-kb-home-path';

type URLBuilderOptions =
    | BasePathOptions
    | DocsHomeURLPathOptions
    | DocumentationPageOptions
    | KbArticlePageOptions
    | KbHomePageOptions;

// eslint-disable-next-line complexity
export function getURLPath(contentType: string, options: URLBuilderOptions) {
    switch (contentType) {
        case HOME_PAGE:
            return '';
        case DOCUMENTED_PRODUCT:
            return getDocsHomePath(options);
        case CONTEXT_APP:
        case CONTEXT:
        case CONTEXT_THEME:
        case PRODUCT_LANDING_PAGE:
            return getBaseURLPath(options);
        case NOTICE_HUB:
            return '';
        case PAGE_KB_HOME:
            return getKbHomePagePath(options);
        case PAGE_KB_ARTICLE:
            return getKbArticlePagePath(options);
        default:
            return getDocumentationPagePath(options);
    }
}
