import type {
    IComponentCodeBlock,
    IComponentCodeBlockFields
} from '@contentful/types';
import { COMPONENT_CODE_BLOCK } from '../../../contentful/content-types';

// contentful-typescript-codegen assumes the REST API, which has a different response format from the GraphQL, is used
// The 'Gql' prefix is used to imply typing that is specific to the GraphQL responses
export interface GqlComponentCodeBlock
    extends Pick<IComponentCodeBlockFields, 'title' | 'code' | 'language'> {
    readonly sys: Pick<IComponentCodeBlock['sys'], 'id'>;
    readonly type: Capitalize<typeof COMPONENT_CODE_BLOCK>;
    readonly __typename: Capitalize<typeof COMPONENT_CODE_BLOCK>;
}

/**
 * Maps `componentCodeBlock`'s language fields to Atlaskit's supported languages.
 * @see https://atlassian.design/components/code/code-block/code
 */
const CODE_LANGUAGE_MAP: Record<
    IComponentCodeBlockFields['language'],
    string | undefined
> = {
    ABAP: 'abap',
    ActionScript: 'actionscript',
    AppleScript: 'applescript',
    Bash: 'bash',
    'C#': 'csharp',
    'C++': 'cpp',
    // Not explicity supported, going with the closest language
    ColdFusion: 'html',
    CSS: 'css',
    // Not explicity supported, going with the closest language
    Delphi: 'pascal',
    Diff: 'diff',
    Erlang: 'erlang',
    Groovy: 'groovy',
    HTML: 'html',
    'Java FX': 'java',
    Java: 'java',
    JavaScript: 'javascript',
    JSON: 'json',
    Other: undefined,
    Perl: 'perl',
    PHP: 'php',
    'Plain text': undefined,
    PowerShell: 'powershell',
    Python: 'python',
    Ruby: 'ruby',
    Sass: 'sass',
    Scala: 'scala',
    SQL: 'sql',
    'Visual Basic': 'vb',
    XML: 'xml',
    YAML: 'yaml'
};

export const getCodeLanguage = (
    input: IComponentCodeBlockFields['language']
): string => {
    return CODE_LANGUAGE_MAP[input] ?? input;
};
