import React from 'react';
import './skip-link.less';

interface LinkProps {
    to?: string;
    text?: string;
}

/**
 * If you use a SkipLink, be sure to add an ID to the element you want to skip to unless it already exists.
 * By default, the ID it targets is '#maincontent'.
 * For more information on skip links, check out https://webaim.org/techniques/skipnav/
 * */
const SkipLink = ({
    to = '#maincontent',
    text = 'Skip to main content'
}: LinkProps) => (
    <div role="navigation" className="skip-link">
        <a href={to}>{text}</a>
    </div>
);

export default SkipLink;
