import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    sentryCaptureEvent,
    sentryCaptureException
} from '../../../../../utils/sentry/sentry-client';
import { Deployment, SortBy } from '../../../types';

export interface SuccessfulSearchResponse {
    queryId: string;
    results: SearchArticle[];
    facets: Facets;
    pagination: Pagination;
    metadata: SearchMetadata;
}

interface SearchMetadata {
    query: {
        originalQuery: string;
        rewrittenQuery: string;
        searchedQuery: string;
    };
    sort: {
        by: string;
    };
    source: string;
}

interface FailedSearchResponse {
    timestamp: number;
    status: number;
    error: string;
    path: string;
}

interface SearchArticle {
    url: string;
    id: string;
    title: string;
    description: string;
    updatedDate: string;
    likes: number;
    views: number;
    version?: string;
    products: {
        platform: string[];
    }[];
}

interface FacetInfo {
    count: number;
    name: string;
}

interface FacetPlatform extends FacetInfo {
    products: { [key: string]: FacetInfo };
}

interface Facets {
    contentType: {
        [key: string]: FacetInfo;
    };
    platform: {
        [key: string]: FacetPlatform;
    };
}

interface Pagination {
    from: number;
    size: number;
    total: number;
}

export interface SearchQuery {
    deployment?: Deployment;
    productsQueryValue?: string[];
    contentTypes?: string[];
    version?: string[];
    sortByQueryValue?: SortBy;
    pageIndex?: number;
}

// These are limitations of Search API v2
export const NUM_OF_RESULTS_PER_PAGE = 10;
export const MAX_NUM_OF_RESULTS = 100;

export const fetchSearchResults = createAsyncThunk(
    'search/fetchSearchResults',
    // eslint-disable-next-line complexity
    async ({
        searchTerm,
        query,
        facets: facetsEnabled = true,
        signal
    }: {
        searchTerm: string;
        query: SearchQuery;
        facets: boolean;
        signal?: AbortSignal;
    }) => {
        const HA_OVERRIDE_DOMAINS = new Set([
            'localhost',
            'support.dev.internal.atlassian.com'
        ]);

        const searchRoute = '/gateway/api/help-content/v2/search';

        const helpAggregatorUrl = HA_OVERRIDE_DOMAINS.has(
            window.location.hostname
        )
            ? `https://support.stg.internal.atlassian.com${searchRoute}`
            : searchRoute;

        const requestBody = {
            query: searchTerm,
            facetFilters: {
                ...(query.contentTypes?.[0] && {
                    contentTypes: query.contentTypes
                }),
                ...((query.deployment ||
                    query.productsQueryValue?.[0] ||
                    query.version?.[0]) && {
                    products: [
                        {
                            ...(query.deployment && {
                                platform: query.deployment
                            }),
                            ...(query.productsQueryValue?.[0] && {
                                key: query.productsQueryValue?.[0]
                            }),
                            ...(query.version?.[0] && {
                                versions: query.version
                            })
                        }
                    ]
                })
            },
            highlights: {
                enable: true
            },
            pagination: {
                from: query.pageIndex ?? 0,
                size: NUM_OF_RESULTS_PER_PAGE
            },
            language: 'en',
            facets: {
                enable: facetsEnabled
            },
            sort: {
                by: query.sortByQueryValue
            }
        };

        try {
            const response = await fetch(helpAggregatorUrl, {
                body: JSON.stringify(requestBody),
                headers: {
                    Accept: '*/*',
                    'Content-Type': 'application/json',
                    'Sec-Fetch-Mode': 'cors',
                    'Sec-Fetch-Site': 'same-origin',
                    authority: 'support.dev.internal.atlassian.com'
                },
                method: 'POST',
                // passed to fetch to allow for cancellation
                signal
            });

            const data = await response.json();
            const queryId = response.headers.get('X-Query-Id');

            if (!response.ok) {
                const { error } = data as FailedSearchResponse;
                sentryCaptureEvent({
                    event: {
                        message: error,
                        level: 'error',
                        contexts: {
                            response: {
                                status_code: response.status
                            }
                        },
                        extra: {
                            queryId,
                            data
                        }
                    }
                });
            }

            return {
                queryId,
                results: data.results,
                facets: data.facets,
                pagination: data.pagination,
                metadata: data.metadata
            };
        } catch (error) {
            if ((error as Error).name === 'AbortError') {
                throw error;
            }
            sentryCaptureException({
                exception: error
            });
            throw error;
        }
    }
);
