import React from 'react';
import './card.less';
import './card-group.less';
import ArrowLeftCircleIcon from '@atlaskit/icon/glyph/arrow-left-circle';
import ArrowRightCircleIcon from '@atlaskit/icon/glyph/arrow-right-circle';
import { translatableText } from '../../utils/translatable-text';
import { AtlasLink } from '../atlas-link';
import camelCase from 'lodash/camelCase';
import Tooltip from '@atlaskit/tooltip';
import analytics from '../../utils/analytics/analytics';

export interface CardGroupProps {
    header?: string;
    count?: number;
    className: string;
    children: string[] | boolean[] | React.ReactNode;
    next?: {
        id: number;
        title: string;
        description: string;
        buttonLink: string;
        buttonText: `{translatableText.viewTopic.text}`;
    };
    previous?: {
        id: number;
        title: string;
        description: string;
        buttonLink: string;
        buttonText: `{translatableText.viewTopic.text}`;
    };
    guide: boolean;
    otherGuideTopicSets: {
        title: string;
        link: string;
        description?: string;
    }[];
}

const handleArrowClick = (value: string) => {
    analytics.trackEvent(value + 'ButtonClick', {
        // @ts-ignore
        event: 'clicked',
        eventComponent: 'button',
        actionSubjectId: `${value}`,
        category: 'upNextHeader',
        action: 'click',
        label: 'up-next ' + value + ' button'
    });
};

// eslint-disable-next-line complexity
export const CardGroup = (props: CardGroupProps) => {
    const {
        header,
        count,
        className,
        children,
        previous,
        next,
        guide,
        otherGuideTopicSets
    } = props;
    const classNames = [
        'cards margin-top-medium',
        className,
        count === 1 ? 'cards-single' : null
    ].join(' ');
    const otherGuideTopicSetsPresent =
        otherGuideTopicSets && otherGuideTopicSets.length > 0;

    if (!guide) {
        return (
            <div data-testid="topic-card" className={classNames}>
                {header && (
                    <h2 className="cards__header secondary-heading">
                        {header}
                    </h2>
                )}
                <ul className="cards__inner">{children}</ul>
            </div>
        );
    }

    return (
        <div
            className={className}
            data-event-container={
                className ? camelCase(className.replace('-', ' ')) : className
            }
        >
            <div className="up-next__header">
                <div className="up-next__header-text-container">
                    {next ? (
                        <h2 className="up-next__header-text">
                            {translatableText.upNext.text + ':'}
                        </h2>
                    ) : (
                        otherGuideTopicSetsPresent && (
                            <h2 className="up-next__header-text--more">
                                {translatableText.moreInThisGuide.text}
                            </h2>
                        )
                    )}
                </div>

                {previous ? (
                    <a
                        data-testid={'prev-button-active'}
                        aria-label="Go to previous topic in this guide."
                        className={'up-next__last-article-button--active'}
                        href={previous.buttonLink}
                        target="_self"
                        onClick={() => handleArrowClick('prev')}
                    >
                        <ArrowLeftCircleIcon label="previous" size="large" />
                    </a>
                ) : (
                    <span
                        data-testid="prev-button-inactive"
                        className="up-next__last-article-button"
                    >
                        <ArrowLeftCircleIcon label="previous" size="large" />
                    </span>
                )}

                {next ? (
                    <a
                        data-testid={'next-button-active'}
                        aria-label="Go to next topic in this guide."
                        className={'up-next__next-article-button--active'}
                        href={next.buttonLink}
                        target="_self"
                        onClick={() => handleArrowClick('next')}
                    >
                        <ArrowRightCircleIcon label="next" size="large" />
                    </a>
                ) : (
                    <span
                        data-testid="next-button-inactive"
                        className="up-next__next-article-button"
                    >
                        <ArrowRightCircleIcon label="previous" size="large" />
                    </span>
                )}
            </div>
            {next ? (
                <div className={classNames}>
                    {header && (
                        <h2 className="cards__header secondary-heading">
                            {header}
                        </h2>
                    )}
                    <ul className="cards__inner">{children}</ul>
                </div>
            ) : (
                otherGuideTopicSetsPresent && (
                    <div className="up-next__also-in">
                        <ul>
                            {otherGuideTopicSets.map((otherGuideTopicSet) => (
                                <li key={otherGuideTopicSet.title}>
                                    <Tooltip
                                        content={otherGuideTopicSet.description}
                                    >
                                        <AtlasLink
                                            href={otherGuideTopicSet.link}
                                            text={otherGuideTopicSet.title}
                                            aria-label={`Go to topic set ${otherGuideTopicSet.title}`}
                                            dataName={camelCase(
                                                otherGuideTopicSet.title
                                            )}
                                        />
                                    </Tooltip>
                                </li>
                            ))}
                        </ul>
                    </div>
                )
            )}
        </div>
    );
};
